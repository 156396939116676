import React, { useEffect, useState } from 'react';
import { useParams } from "react-router-dom";
import { Box, Grid, Paper, Typography, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Checkbox, Button, useTheme, TextField } from '@mui/material';

import { GeneraFormularioSimple } from "../../components/globals/globalForms";
import { newArticle } from "../../data/forms/articles";
import * as yup from "yup";

import { useSnackbar } from 'notistack';
import { tokens } from "../../data/tokens";

import { usersFields } from "../../data/forms/users";

import SearchFilters from "../globals/searchFilters";
import calculatePrecioVenta from "../globals/sellPrice";

import { IconsDefault } from "../globals/globalForms";
import { searchParams } from "../../data/search/addArticles";

import { sendPostData, getPostData, getDataArray } from '../../system/getData';
import { UseAppConfig } from '../../system/config';

const formatCurrency = (value) => {
	return new Intl.NumberFormat('es-MX', { style: 'currency', currency: 'MXN' }).format(value);
};

export const ViewArticle = ({ id, catID = 0, precioVenta, section = '' }) => {
	const theme = useTheme();
	const colors = tokens(theme.palette.mode);

	const { config, configData } = UseAppConfig();
	const porcentajeGlobal = configData ? configData.porcentajeGlobal : 0;

	const [rows, setRows] = useState([]);
	const [isLoading, setIsLoading] = useState(true);

	const [artValues, setArtValues] = useState(null);
	const [loading, setLoading] = useState(true);

	const fetchDataTable = async () => {
		try {
			setIsLoading(true);
			const url = section === 'projects'
				? `${config.API_BASE_URL}/elementos/proyecto`
				: `${config.API_BASE_URL}/elementos/compuesto`;

			const jsonData = await getDataArray(url, { catID, id });
			const porcentajeGlobal = configData ? configData.porcentajeGlobal : 0;

			const formatData = jsonData.map(row => {
				const { precioVentaValor, precioVentaFormatted, isPrecioVentaMenor } = calculatePrecioVenta(
					row.precio,
					row.precioVenta,
					porcentajeGlobal
				);

				return {
					...row,
					precioCompra: formatCurrency(row.precio),
					precioVenta: formatCurrency(precioVentaValor),
					isPrecioVentaMenor
				};
			});

			setRows(formatData);
			console.log('Detalles del Registro: ' + JSON.stringify(jsonData));
		} catch (error) {
			console.error('Se ha generado un error a la hora de intentar obtener los datos:', error);
			setRows([]);
		} finally {
			setIsLoading(false);
		}
	};

	useEffect(() => {
		fetchDataTable();
	}, [id]);

	useEffect(() => {
		if (id) {
			const fetchData = async () => {
				try {
					const url = section === 'projects'
						? `${config.API_BASE_URL}/datos/proyecto`
						: `${config.API_BASE_URL}/datos/registro`;

					const initVals = await getPostData(url, { id });
					setArtValues(initVals);
				} catch (error) {
					console.error('Error al cargar los valores iniciales:', error);
				} finally {
					setLoading(false);
				}
			};
			fetchData();
		}
	}, [id]);

	if (loading) {
		return <div>Cargando...</div>;
	}

	const ColorsDefault = ({ id, nombre, tipo }) => {
		const styleConfig = {
			'2': { color: 'green', cursor: 'normal' },
			'1': { color: 'blue', cursor: 'normal' },
		};

		const defaultStyle = { color: 'red', fontSize: '14px', fontWeight: 'normal' };
		const appliedStyle = styleConfig[tipo] || defaultStyle;

		return (
			<span style={appliedStyle}>
				{nombre}
			</span>
		);
	};

	return (
		<Grid>
			<h4>Detalle del artículo: {artValues.descCorta} ({artValues.clave})</h4>
			<Paper elevation={3} className="panel">
				<Box p={3} className="panel-body">
					<Grid container spacing={2}>
						<Grid item xs={12} md={artValues.tipo === "Compuesto" ? 5 : 12}>
							<Typography
								sx={{
									fontSize: '15px',
									lineHeight: '1.6',
								}}
							>
								<strong>Nombre:</strong> {artValues.descCorta}<br />
								<strong>Clave:</strong> {artValues.clave}<br />
								<strong>Precio de Compra:</strong> {formatCurrency(artValues.precioCompra)}<br />
								<strong>Tipo:</strong> {artValues.tipo}<br />
								<strong>Familia:</strong> {artValues.familia}<br />
								<strong>Cuenta:</strong> {artValues.cuenta}<br />
								<strong>Tipo de Unidad:</strong> por {artValues.unidad}<br />
								<strong>Descripción del Servicio:</strong><br />
								{artValues.descLarga}
							</Typography>
						</Grid>

						{artValues.tipo === "Compuesto" && (
							<Grid item xs={12} md={7}>
								<b>Este Compuesto contiene los siguientes artículos:</b>
								<br /><br />
								<TableContainer
									sx={{
										maxHeight: '340px',
										overflowY: 'auto'
									}}
								>
									<Table>
										<TableHead>
											<TableRow style={{ backgroundColor: colors.grey[900], color: 'white' }}>
												<TableCell className="per5 text-center"><b>Clave</b></TableCell>
												<TableCell className="per5 text-center"><b>Familia</b></TableCell>
												<TableCell className="per5 text-center"><b>Cantidad</b></TableCell>
												<TableCell className="per25 text-center"><b>Precio de Venta</b></TableCell>
											</TableRow>
										</TableHead>
										<TableBody>
											{rows.map((row, index) => (
												<TableRow
													key={row.id}
													className={index % 2 === 0 ? 'row-white' : 'row-gray'}
												>
													<TableCell className="text-center">
														<IconsDefault tipo={row.tipo} icon='' />
														<span style={{ marginLeft: '5px', fontSize: '20px' }}></span>
														<ColorsDefault
															id={row.id}
															nombre={row.hijo}
															tipo={row.tipo}
														/>
													</TableCell>
													<TableCell className="text-center">{row.nombreFamilia}</TableCell>
													<TableCell className="text-center">{row.cantidad}</TableCell>
													<TableCell
														sx={{
															textAlign: 'center',
														}}
														className={row.isPrecioVentaMenor ? 'red-text' : ''}
													>
														{row.precioVenta}
													</TableCell>
												</TableRow>
											))}
										</TableBody>
									</Table>
								</TableContainer>
							</Grid>
						)}
					</Grid>
				</Box>
			</Paper >
		</Grid >
	);
}

export const AddArticles = ({ proyID, catID, catName, reloadData, setModalOpen }) => {
	const [rows, setRows] = useState([]);
	const { enqueueSnackbar } = useSnackbar();
	const [loading, setLoading] = useState(true);

	const [selectedItems, setSelectedItems] = useState([]);

	const theme = useTheme();
	const colors = tokens(theme.palette.mode);

	const { config, configData } = UseAppConfig();

	const fetchDataTable = async (params = {}) => {
		try {
			const jsonData = await getDataArray(`${config.API_BASE_URL}/artproy/disponibles`, { proyID, catID, params });
			const formatData = jsonData.map(row => ({
				...row,
				status: row.status == 1 ? 'Activo' : 'Desactivado'
			}));
			setRows(formatData);
			console.log('Detalles del Registro: ' + JSON.stringify(jsonData));
		} catch (error) {
			console.error('Se ha generado un error a la hora de intentar obtener los datos:', error);
			setRows([]);
		} finally {
			setLoading(false);
		}
	};

	useEffect(() => {
		const savedFilters = localStorage.getItem('searchFilters');
		if (savedFilters) {
			const parsedFilters = JSON.parse(savedFilters);
			fetchDataTable(parsedFilters);
		} else {
			fetchDataTable();
		}
	}, [proyID]);

	if (loading) {
		return <div>Cargando...</div>;
	}

	const handleSearchSubmit = (values) => {
		fetchDataTable(values);
	};

	const handleCheckboxChange = (id) => {
		setSelectedItems(prevSelectedItems => {
			const isSelected = prevSelectedItems.some(item => item.id === id);
			if (isSelected) {
				return prevSelectedItems.filter(item => item.id !== id);
			} else {
				return [...prevSelectedItems, { proyID, catID, id }];
			}
		});
	};

	const handleSave = async () => {
		try {
			console.log('Antes de enviar datos');
			const url = `${config.API_BASE_URL}/agrega/articulosCatProy`;
			const params = { items: selectedItems };
			const result = await sendPostData(url, params);
			if (reloadData) {
				reloadData();
			}
			if (setModalOpen) {
				setModalOpen(false);
			}
			enqueueSnackbar('Artículos agregados exitosamente al Compuesto', { variant: 'success' });
		} catch (error) {
			console.error('Error al intentar agregar los Artículos al Compuesto:', error);
			enqueueSnackbar('Error al intentar agregar los Artículos al Compuesto', { variant: 'error' });
		}
	};

	const ColorsDefault = ({ id, nombre, tipo }) => {
		const styleConfig = {
			'Compuesto': { color: 'green', cursor: 'normal' },
			'Simple': { color: 'blue', cursor: 'normal' },
		};

		const defaultStyle = { color: 'red', fontSize: '13px', fontWeight: 'normal' };
		const appliedStyle = styleConfig[tipo] || defaultStyle;

		return (
			<span style={appliedStyle}>
				{nombre}
			</span>
		);
	};

	return (
		<div>
			<h5>Agregar Artículos dentro del Conjunto: {catName}</h5>
			<SearchFilters data={searchParams} onSubmit={handleSearchSubmit} />
			<br /><br />
			<TableContainer>
				<Table>
					<TableHead>
						<TableRow style={{ backgroundColor: colors.grey[900] }}>
							<TableCell className="per5 text-center" style={{ width: '120px' }}><b>Clave</b></TableCell>
							<TableCell className="per5 text-center"><b>Descripción</b></TableCell>
							<TableCell className="per5 text-center"><b>Familia</b></TableCell>
							<TableCell className="per5 text-center"><b>Tipo</b></TableCell>
							<TableCell className="per5 text-center"><b>Cuenta</b></TableCell>
							<TableCell className="per5 text-center"><b>Unidad</b></TableCell>
							<TableCell className="per5 text-center"><b>Precio de Compra</b></TableCell>
							<TableCell className="per5 text-center"><b>Agregar</b></TableCell>
						</TableRow>
					</TableHead>
					<TableBody>
						{rows.map((row) => (
							<TableRow key={row.id}>
								<TableCell className="text-center">
									<IconsDefault tipo={row.tipo} icon='' />
									<span style={{ marginLeft: '5px', fontSize: '20px' }}></span>
									<ColorsDefault
										id={row.id}
										nombre={row.clave}
										tipo={row.tipo}
									/>
								</TableCell>
								<TableCell className="text-center">{row.descCorta}</TableCell>
								<TableCell className="text-center">{row.familia}</TableCell>
								<TableCell className="text-center">{row.tipo}</TableCell>
								<TableCell className="text-center">{row.cuenta}</TableCell>
								<TableCell className="text-center">{row.unidad}</TableCell>
								<TableCell className="text-center">{formatCurrency(row.precioCompra)}</TableCell>
								<TableCell className="text-center">
									<Checkbox
										checked={selectedItems.some(item => item.id === row.id)}
										onChange={() => handleCheckboxChange(row.id)}
										disabled={row.precioCompra === 9 || row.precioCompra === "" || row.precioCompra === null}
									/>
								</TableCell>
							</TableRow>
						))}
					</TableBody>
				</Table>
			</TableContainer>
			<div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%', marginTop: '20px' }}>
				<Button variant="contained" color="secondary" onClick={handleSave}>
					Agregar Elementos
				</Button>
			</div>
		</div>
	);
};

export const CreateArticle = ({ reloadData, setModalOpen }) => {
	const initValues = {
		clave: '',
		tipoArtID: '',
		famID: '',
		cuentaID: '',
		unidadID: '',
		descCorta: '',
		descLarga: '',
		imagen: ''
	};

	const dataConfig = {
		dbID: "id",
		title: "",
		key: newArticle,
		module: 'articles',
		description: "",
		titleBtn: "Crear Artículo",
		colorBtn: "secondary",
		msgSuccess: "Articulo creado exitosamente",
		msgError: "Error al crear el Registro",
		sendData: "nuevo/articulo"
	}

	const validateSchemas = yup.object().shape({
		clave: yup.string().required('Requerido'),
		tipoArtID: yup.number().required('Requerido'),
		famID: yup.number().required('Requerido'),
		cuentaID: yup.number().required('Requerido'),
		unidadID: yup.number().required('Requerido'),
		descCorta: yup.string(),
		descLarga: yup.string(),
		imagen: yup.string().required('Requerido')
	});

	return (
		<>
			<Typography variant="h5">
				Crear un Nuevo Articulo
				<br /><br />
				<small>Ingrese los datos necesarios para crear un Artículo Simple o Compuesto según sean sus necesidades:</small>
				<hr noshade></hr>
			</Typography>

			<GeneraFormularioSimple
				data={dataConfig}
				initValues={initValues}
				validateSchemas={validateSchemas}
				reloadData={reloadData}
				setModalOpen={setModalOpen}
			/>
		</>
	)
}

export const EditGlobalPercent = ({ proyID }) => {
	const { config, configData } = UseAppConfig();
	const { enqueueSnackbar } = useSnackbar();

	const porcentajeGlobal = configData ? configData.porcentajeGlobal : 0;

	const [sectionFormValues, setSectionFormValues] = useState({
		GlobalPercent: porcentajeGlobal,
	});

	const handleSectionChange = (e) => {
		const { name, value } = e.target;
		setSectionFormValues((prevValues) => ({
			...prevValues,
			[name]: value,
		}));
	};

	const handleSectionSubmit = async (e) => {
		e.preventDefault();
		await handleFormSection(sectionFormValues);
	};

	const handleFormSection = async (values) => {
		try {
			const result = await sendPostData(`${config.API_BASE_URL}/edita/porcentajeGlobal`, values);
			enqueueSnackbar('Porcentaje Editado Exitosamente', { variant: 'success' });
			window.location.reload();
		} catch (error) {
			enqueueSnackbar('Ha ocurrido un error al intentar actualizar el Porcentaje Global', { variant: 'error' });
		}
	};

	return (
		<div>
			<Typography variant="h5" gutterBottom>
				Actualizar Porcentaje Global
				<br /><br />
				<small>El Porcentaje Global se utiliza para calcular el precio de venta de todos los artículos registrados:</small>
				<hr noshade></hr>
			</Typography>
			<Box
				component="form"
				sx={{
					'& .MuiTextField-root': { margin: 1 },
				}}
				noValidate
				textAlign="center"
				marginLeft='auto'
				marginRight='auto'
				autoComplete="off"
			>
				<TextField
					type="number"
					variant="outlined"
					label="Porcentaje"
					name="GlobalPercent"
					value={sectionFormValues.GlobalPercent}
					onChange={handleSectionChange}
					sx={{
						width: "70px",
						marginBottom: 1,
					}}
				/>
				<Button
					variant="contained"
					sx={{
						marginTop: 2,
						backgroundColor: 'green',
						color: 'white',
						display: 'block',
						marginLeft: 'auto',
						marginRight: 'auto'
					}}
					onClick={handleSectionSubmit}
				>
					Actualizar Porcentaje
				</Button>
			</Box>
		</div>
	);
};

export const CreateSection = ({ proyID }) => {
	const { config } = UseAppConfig();
	const { enqueueSnackbar } = useSnackbar();

	const [sectionFormValues, setSectionFormValues] = useState({
		proyID: proyID,
		secTitle: '',
		secDesc: ''
	});

	const handleSectionChange = (e) => {
		const { name, value } = e.target;
		setSectionFormValues((prevValues) => ({
			...prevValues,
			[name]: value,
		}));
	};

	const handleSectionSubmit = async (e) => {
		e.preventDefault();
		await handleFormSection(sectionFormValues);
	};

	const handleFormSection = async (values) => {
		try {
			const result = await sendPostData(`${config.API_BASE_URL}/crea/categoria`, values);
			enqueueSnackbar('Sección Guardada Exitosamente', { variant: 'success' });
			window.location.reload();
		} catch (error) {
			enqueueSnackbar('Ha ocurrido un error al intentar crear el nueva sección', { variant: 'error' });
		}
	};

	return (
		<div>
			<Typography variant="h5" gutterBottom>
				Crear una nueva Categoría
			</Typography>
			<Box
				component="form"
				sx={{
					'& .MuiTextField-root': { margin: 1, width: '100%' },
				}}
				noValidate
				autoComplete="off"
			>
				<TextField
					required
					id="project-title"
					label="Título de la Sección"
					variant="outlined"
					name="secTitle"
					value={sectionFormValues.secTitle}
					onChange={handleSectionChange}
					fullWidth
				/>
				<TextField
					required
					id="project-description"
					label="Descripción"
					variant="outlined"
					name="secDesc"
					value={sectionFormValues.secDesc}
					onChange={handleSectionChange}
					multiline
					rows={4}
					fullWidth
				/>
				<Button
					variant="contained"
					sx={{
						marginTop: 2,
						backgroundColor: 'green',
						color: 'white',
						display: 'block',
						marginLeft: 'auto',
						marginRight: 'auto'
					}}
					onClick={handleSectionSubmit}
				>
					Crear Sección
				</Button>
			</Box>
		</div>
	);
};

export const EditSection = ({ data, reloadData, setModalOpen }) => {
	const { config } = UseAppConfig();
	const { enqueueSnackbar } = useSnackbar();

	const [isLoading, setIsLoading] = useState(true);
	const [formValues, setFormValues] = useState({
		catID: data,
		secTitle: '',
		secDesc: ''
	});

	const fetchData = async () => {
		try {
			const jsonData = await getDataArray(`${config.API_BASE_URL}/detalle/categoria`, { id: data });
			console.log('Datos obtenidos del API:', jsonData);
			if (jsonData && jsonData.projectDetails && jsonData.projectDetails.length > 0) {
				const row = jsonData.projectDetails[0];
				const formattedData = {
					catID: row.id || '',
					secTitle: row.nombre || '',
					secDesc: row.descripcion || ''
				};
				setFormValues(formattedData);
			} else {
				console.warn('No se encontraron datos en la respuesta del API');
			}
		} catch (error) {
			console.error('Error al obtener los datos:', error);
		} finally {
			setIsLoading(false);
		}
	};

	useEffect(() => {
		fetchData();
	}, [data]);

	if (isLoading) {
		return <div>Cargando...</div>;
	}

	const handleSectionSubmit = async (e) => {
		e.preventDefault();
		await handleFormSection(formValues);
	};

	const handleFormSection = async (values) => {
		try {
			await sendPostData(`${config.API_BASE_URL}/edita/categoria`, values);
			if (reloadData) {
				reloadData();
			}
			if (setModalOpen) {
				setModalOpen(false);
			}
			enqueueSnackbar('Cambios guardados exitosamente', { variant: 'success' });
		} catch (error) {
			enqueueSnackbar('Ha ocurrido un error al intentar crear la nueva sección', { variant: 'error' });
		}
	};

	return (
		<div>
			<Typography variant="h5" gutterBottom>
				Editar los datos de esta Categoría
			</Typography>
			<Box
				component="form"
				sx={{
					'& .MuiTextField-root': { margin: 1, width: '100%' },
				}}
				noValidate
				autoComplete="off"
			>
				<TextField
					required
					id="category-title"
					label="Nombre de la Categoría"
					variant="outlined"
					name="secTitle"
					value={formValues.secTitle}
					onChange={(e) => setFormValues({ ...formValues, secTitle: e.target.value })}
					fullWidth
				/>
				<TextField
					required
					id="category-description"
					label="Descripción"
					variant="outlined"
					name="secDesc"
					value={formValues.secDesc}
					onChange={(e) => setFormValues({ ...formValues, secDesc: e.target.value })}
					multiline
					rows={4}
					fullWidth
				/>
				<Button
					variant="contained"
					sx={{
						marginTop: 2,
						backgroundColor: 'green',
						color: 'white',
						display: 'block',
						marginLeft: 'auto',
						marginRight: 'auto'
					}}
					onClick={handleSectionSubmit}
				>
					Guardar Cambios
				</Button>
			</Box>
		</div>
	);
};

export const CreateProject = ({ data }) => {
	const { config } = UseAppConfig();
	const { enqueueSnackbar } = useSnackbar();

	// Estado inicial basado en `data`
	const [projectFormValues, setProjectFormValues] = useState(() => {
		if (data !== "") {
			return {
				proyId: data.id,
				proyTitle: data.nombre,
				proyDesc: data.descripcion,
				proyImagen: data.imagen,
			};
		} else {
			return {
				proyTitle: '',
				proyDesc: '',
			};
		}
	});

	const handleProjectChange = (e) => {
		const { name, value } = e.target;
		setProjectFormValues((prevValues) => ({
			...prevValues,
			[name]: value,
		}));
	};

	const handleProjectSubmit = async (e) => {
		e.preventDefault();
		await handleFormProject(projectFormValues);
	};

	const handleFormProject = async (values) => {
		try {
			if (data !== "") {
				const result = await sendPostData(`${config.API_BASE_URL}/edita/proyecto`, values);
				enqueueSnackbar('Proyecto Guardado Exitosamente', { variant: 'success' });
			} else {
				const result = await sendPostData(`${config.API_BASE_URL}/nuevo/proyecto`, values);
				enqueueSnackbar('Proyecto Guardado Exitosamente', { variant: 'success' });
			}
			window.location.reload();
		} catch (error) {
			enqueueSnackbar('Ha ocurrido un error al intentar crear el nuevo proyecto', { variant: 'error' });
		}
	};
	return (
		<div>
			{data != '' ? (
				<Typography variant="h5" gutterBottom>
					Editar los datos de este Proyecto
				</Typography>
			) : (
				<Typography variant="h5" gutterBottom>
					Creación de Nuevo Proyecto
				</Typography>
			)}
			<Box
				component="form"
				sx={{
					'& .MuiTextField-root': { margin: 1, width: '100%' },
				}}
				noValidate
				autoComplete="off"
			>
				<TextField
					required
					id="project-title"
					label="Título del Proyecto"
					variant="outlined"
					name="proyTitle"
					value={projectFormValues.proyTitle}
					onChange={handleProjectChange}
					fullWidth
				/>
				<TextField
					required
					id="project-description"
					label="Descripción"
					variant="outlined"
					name="proyDesc"
					value={projectFormValues.proyDesc}
					onChange={handleProjectChange}
					multiline
					rows={10}
					fullWidth
				/>
				{data != '' ? (
					<Button
						variant="contained"
						sx={{
							marginTop: 2,
							backgroundColor: 'warning',
							color: 'white',
							display: 'block',
							marginLeft: 'auto',
							marginRight: 'auto'
						}}
						onClick={handleProjectSubmit}
					>
						Editar Proyecto
					</Button>
				) : (
					<Button
						variant="contained"
						sx={{
							marginTop: 2,
							backgroundColor: 'info',
							color: 'white',
							display: 'block',
							marginLeft: 'auto',
							marginRight: 'auto'
						}}
						onClick={handleProjectSubmit}
					>
						Crear Proyecto
					</Button>
				)}
			</Box>
		</div>
	);
};

export const CreateUser = ({ data, reloadData, setModalOpen }) => {
	const { config } = UseAppConfig();
	const { enqueueSnackbar } = useSnackbar();

	const initValues = {
		nombre: '',
		apPat: '',
		apMat: '',
		correo: '',
		telefono1: '',
		telefono2: '',
		direccion: '',
		tipoUsuID: '',
		status: ''
	};

	const dataConfig = {
		dbID: "id",
		title: "",
		key: usersFields,
		section: 'users',
		module: 'users',
		description: "",
		titleBtn: "Nuevo Usuario",
		colorBtn: "secondary",
		msgSuccess: "Usuario creado exitosamente",
		msgError: "Error al crear el usuario",
		sendData: "nuevo/usuario"
	}

	const validateSchemas = yup.object().shape({
		nombre: yup.string().required('Requerido'),
		apPat: yup.string().required('Requerido'),
		apMat: yup.string().required('Requerido'),
		correo: yup.string().email('Correo inválido').required('Requerido'),
		telefono1: yup.string(),
		telefono2: yup.string(),
		direccion: yup.string(),
		tipoUsuID: yup.number().required('Requerido'),
	});

	return (
		<>
			<Typography variant="h5" gutterBottom>
				Creación de Nuevo Usuario
			</Typography>
			<small>Ingrese los datos necesarios para crear un Nuevo Usuario</small>
			<GeneraFormularioSimple
				data={dataConfig}
				initValues={initValues}
				validateSchemas={validateSchemas}
				reloadData={reloadData}
				setModalOpen={setModalOpen}
			/>
		</>
	)
};

export const EditUser = ({ userID, reloadData, setModalOpen }) => {
	const { config } = UseAppConfig();
	const { enqueueSnackbar } = useSnackbar();

	const [initValues, setInitValues] = useState(null);
	const [loading, setLoading] = useState(true);

	const dataConfig = {
		dbID: "id",
		title: "",
		key: usersFields,
		section: 'users',
		module: 'users',
		description: "",
		titleBtn: "Editar Usuario",
		colorBtn: "secondary",
		msgSuccess: "Usuario editado exitosamente",
		msgError: "Error al editar el usuario",
		getData: "detalle/usuario",
		sendData: "edita/usuario"
	}

	const validateSchemas = yup.object().shape({
		nombre: yup.string().required('Requerido'),
		apPat: yup.string().required('Requerido'),
		apMat: yup.string().required('Requerido'),
		correo: yup.string().email('Correo inválido').required('Requerido'),
		telefono1: yup.string(),
		telefono2: yup.string(),
		direccion: yup.string(),
		tipoUsuID: yup.number().required('Requerido'),
	});

	const fetchDataTable = async () => {
		try {
			const initVals = await getPostData(`${config.API_BASE_URL}/${dataConfig.getData}`, { id: userID });
			setInitValues(initVals);
			console.log('Datos del Usuario: ' + JSON.stringify(initVals));
		} catch (error) {
			console.error('Se ha generado un error a la hora de intentar obtener los datos:', error);
		} finally {
			setLoading(false);
		}
	};

	useEffect(() => {
		fetchDataTable();
	}, [userID, dataConfig, config]);

	if (loading) {
		return <div>Cargando...</div>;
	}

	return (
		<>
			<Typography variant="h5" gutterBottom>
				Edición de datos de Usuario
			</Typography>
			<small>Ingrese los datos necesarios para editar los datos de este Usuario</small>
			<GeneraFormularioSimple
				data={dataConfig}
				initValues={initValues}
				validateSchemas={validateSchemas}
				reloadData={reloadData}
				setModalOpen={setModalOpen}
			/>
		</>
	);
};