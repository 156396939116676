import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Box, TextField, Container, Grid, Typography, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Button, useTheme } from '@mui/material';

import { Formik } from "formik";
import { useSnackbar } from 'notistack';
import { tokens } from "../../data/tokens";
import { useNavigate } from 'react-router-dom';
import { sendPostData, getDataArray } from '../../system/getData';
import useMediaQuery from "@mui/material/useMediaQuery";

import { GetCompoundAmounts, CompoundDetails } from "../compounds/compoundList";
import ComboBoxFilled from "../globals/comboBoxFilled";

import { UseAppConfig } from '../../system/config';
import { Header } from "../basics/Header";

export const GeneraFormularioSimple = ({ data, initValues, validateSchemas, reloadData, setModalOpen }) => {
	const navigate = useNavigate();
	const { enqueueSnackbar } = useSnackbar();
	const isNonMobile = useMediaQuery("(min-width:600px)");

	const { config, configData } = UseAppConfig();

	const handleFormSubmit = async (values) => {
		console.log('Detalles del Registro: ' + JSON.stringify(values));
		console.log('Enviando datos a la API');
		try {
			const result = await sendPostData(`${config.API_BASE_URL}/${data.sendData}`, values);
			console.log('Formulario actualizado exitosamente:', result);
			enqueueSnackbar(data.msgSuccess, { variant: 'success' });
			if (reloadData) {
				reloadData();
			} else {
				navigate(`/${data.module}`);
			}
			if (setModalOpen) {
				setModalOpen(false);
			}
		} catch (error) {
			console.error('Error al enviar datos:', error);
			enqueueSnackbar(data.msgError, { variant: 'error' });
		}
	};

	return (
		<Box
			sx={{
				width: "100%",
				borderRadius: "10px",
			}}
		>
			<Header title={data.title} subtitle={data.description} />
			<Formik
				onSubmit={handleFormSubmit}
				initialValues={initValues}
			>
				{({
					values,
					errors,
					touched,
					handleBlur,
					handleChange,
					handleSubmit,
					setFieldValue,
				}) => (
					<form onSubmit={(e) => {
						console.log('Formulario intentando enviarse');
						handleSubmit(e);
					}}>
						<Box
							sx={{
								width: "100%",
								margin: "0 auto",
								borderRadius: "15px",
								display: "grid",
								gap: "10px",
								gridTemplateColumns: "repeat(4, minmax(0, 1fr))",
							}}
						>
							{data.key.map((field, index) => (
								field.catalog?.length > 0 ? (
									<ComboBoxFilled
										data={field}
										index={index}
										value={values[field.name]}
										onChange={(event) => setFieldValue(field.name, event.target.value)}
									/>
								) : (
									<div key={index}>
										<b style={{ marginTop: '20px', display: 'block' }}>{field.label}</b>
										<TextField
											key={index}
											fullWidth
											variant="filled"
											type="text"
											onBlur={handleBlur}
											onChange={handleChange}
											value={values[field.name]}
											name={field.name}
											disabled={field.disabled}
											error={!!touched[field.name] && !!errors[field.name]}
											helperText={touched[field.name] && errors[field.name]}
											sx={{ gridColumn: field.gridColumn }}
										/>
									</div>
								)
							))}
						</Box>
						<Box display="flex" justifyContent="end" mt="20px">
							<Button type="submit" color={data.colorBtn} variant="contained">
								{data.titleBtn}
							</Button>
						</Box>
					</form>
				)}
			</Formik>
		</Box>
	);
};

export const FormatoArticulosCompuestos = ({ data, initValues, validateSchemas }) => {
	const [setRows] = useState([]);
	const navigate = useNavigate();

	const theme = useTheme();
	const colors = tokens(theme.palette.mode);

	const currentDate = new Date();
	const { configData } = UseAppConfig();

	const porcentajeGlobal = configData ? configData.porcentajeGlobal : 0;

	const formattedDate = currentDate.toLocaleDateString('es-ES', {
		weekday: 'long',
		year: 'numeric',
		month: 'long',
		day: 'numeric'
	});

	const handleBack = () => {
		navigate(-1);
	};

	const headerTitle = () => {
		return data.title + ' ' + initValues.descCorta;
	}

	return (
		<Grid>
			<Paper elevation={3} className="panel">
				<Box p={3} className="panel-body">
					<Grid>
						<Box className="invoice-details" mt={3}>
							<Header title={headerTitle()} subtitle={data.description} />
							<Box sx={{ display: 'flex', justifyContent: 'flex-end', marginTop: '0px' }}>
								<Button variant="contained" onClick={handleBack} color="success" autoFocus>
									Regresar
								</Button>
							</Box>
							<Box className="invoice-to" mt={3}>
								<Paper style={{ padding: '16px' }}>
									<Grid className="list-unstyled mb0" container spacing={2}>
										<Grid item xs={12} md={6}>
											<ul>
												<li><strong>Nombre:</strong> {initValues.descCorta}</li>
												<li><strong>Clave:</strong> {initValues.clave}</li>
												<li><strong>Tipo:</strong> {initValues.tipo}</li>
												<li><strong>Familia:</strong> {initValues.familia}</li>
												<li><strong>Cuenta:</strong> {initValues.cuenta}</li>
												<li><strong>Tipo de Unidad:</strong> por {initValues.unidad}</li>
												<li><strong>Porcentaje Global:</strong> {porcentajeGlobal}%</li>
												<br /><br />
												<li style={{ width: '100%', marginBottom: '35px' }}>
													<strong>Descripción del Servicio:</strong><br />
													{initValues.descLarga}
												</li>
											</ul>
										</Grid>

										<Grid item xs={12} md={6}>
											<GetCompoundAmounts
												data={data}
												tipo={initValues.tipo}
												initValues={initValues}
											/>
										</Grid>
									</Grid>
								</Paper>
								<Box >
									<br /><br />
									<CompoundDetails
										data={data}
										tipo={initValues.tipo}
										initValues={initValues}
									/>
								</Box>
							</Box>
						</Box>
					</Grid>
				</Box>
			</Paper >
		</Grid >
	);
};

export const IconsDefault = ({ tipo, icon = '' }) => {
	const [iconSrc, setIconSrc] = useState('');

	useEffect(() => {
		let resolvedIcon = '';

		if (tipo === 1) tipo = 'Simple';
		if (tipo === 2) tipo = 'Compuesto';

		if (icon.length !== 0) {
			resolvedIcon = icon;
		} else {
			switch (tipo) {
				case 'Categoría':
					resolvedIcon = 'openBox.png';
					break;
				case 'Simple':
					resolvedIcon = 'barCode.png';
					break;
				case 'Compuesto':
					resolvedIcon = 'QR.png';
					break;
				default:
					resolvedIcon = 'defaultIcon.png';
			}
		}

		setIconSrc(`/assets/icons/${resolvedIcon}`);
	}, [tipo, icon]);

	return (
		<img src={iconSrc} width="20px" alt={tipo} />
	);
};