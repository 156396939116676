import React, { useEffect, useState } from 'react';

import { Box, Typography, IconButton, Button, Modal, Paper } from "@mui/material";
import { motion } from "framer-motion";
import { Link } from "react-router-dom";
import PersonIcon from "@mui/icons-material/Person";
import InsertDriveFile from "@mui/icons-material/InsertDriveFile";
import CategoryIcon from "@mui/icons-material/Category";
import AttachMoneyIcon from "@mui/icons-material/AttachMoney";

import { UseAppConfig } from '../../system/config';
import { EditGlobalPercent } from "../../components/projects/addElements";

const AnimatedBox = motion(Box);

const sections = [
	{
		title: "Usuarios",
		description: "Gestión de usuarios registrados.",
		icon: <PersonIcon sx={{ fontSize: 50 }} />,
		color: "#4caf50",
		link: "/users",
	},
	{
		title: "Artículos",
		description: "Control de inventario y existencias.",
		icon: <InsertDriveFile sx={{ fontSize: 50 }} />,
		color: "#2196f3",
		link: "/articles",
	},
	{
		title: "Catálogos",
		description: "Organización de categorías y elementos.",
		icon: <CategoryIcon sx={{ fontSize: 50 }} />,
		color: "#ff9800",
		link: "/catalogs",
	},
	{
		title: "Presupuestos",
		description: "Control y planificación financiera.",
		icon: <AttachMoneyIcon sx={{ fontSize: 50 }} />,
		color: "#e91e63",
		link: "/projects",
	},
];

const Dashboard = () => {
	const { configData } = UseAppConfig();

	const [modalOpen, setModalOpen] = useState(false);
	const [modalSection, setModalSection] = useState(false);

	const porcentajeGlobal = configData ? configData.porcentajeGlobal : 0;

	const handleModalOpen = (section) => {
		setModalSection(section);
		setModalOpen(true);
	};

	const handleModalClose = () => {
		setModalOpen(false);
	};

	const renderModalContent = () => {
		switch (modalSection) {
			case 'editPercent':
				return <EditGlobalPercent data={1} />;
			default:
				return null;
		}
	};

	return (
		<Box style={{ paddingLeft: "20px" }}>
			<Box
				sx={{
					background: "linear-gradient(140deg, #1E293B, #4CA9EF)",
					color: "#fff",
					textAlign: "center",
					py: 8,
					px: 4,
					borderRadius: "0 0 20px 20px",
				}}
			>
				<Typography variant="h2" fontWeight="bold" gutterBottom>
					Precios Unitarios
				</Typography>
				<Typography variant="h5" fontWeight="300" maxWidth="600px" mx="auto" gutterBottom>
					Administra fácilmente tus datos con herramientas modernas y flexibles diseñadas para
					maximizar tu eficiencia.
				</Typography>
			</Box>

			{/* Cards Section */}
			<Box
				display="flex"
				justifyContent="center"
				flexWrap="wrap"
				gap="20px"
				mt="40px"
				px="20px"
			>
				{sections.map((section, index) => (
					<Link
						to={section.link}
						key={index}
						style={{ textDecoration: "none", width: "300px" }}
					>
						<AnimatedBox
							sx={{
								backgroundColor: section.color,
								borderRadius: "15px",
								padding: "20px",
								height: "200px",
								color: "#fff",
								display: "flex",
								flexDirection: "column",
								alignItems: "center",
								justifyContent: "center",
								boxShadow: "0 6px 15px rgba(0, 0, 0, 0.2)",
								textAlign: "center",
							}}
							whileHover={{ scale: 1.05, rotate: 2 }}
							initial={{ opacity: 0, y: 20 }}
							animate={{ opacity: 1, y: 0 }}
							transition={{ duration: 0.5 }}
						>
							<IconButton
								sx={{
									backgroundColor: "rgba(255, 255, 255, 0.3)",
									mb: 2,
									color: "#fff",
								}}
							>
								{section.icon}
							</IconButton>
							<Typography variant="h6" fontWeight="bold">
								{section.title}
							</Typography>
							<Typography variant="body2" mt={1}>
								{section.description}
							</Typography>
						</AnimatedBox>
					</Link>
				))}
			</Box>

			<Box
				mt="60px"
				px="20px"
				py="40px"
				sx={{
					backgroundColor: "#f5f5f5",
					textAlign: "center",
					borderRadius: "20px",
				}}
			>
				<Box>
				<h6><strong>Todos los Precios de Venta se aplican con el Porcentaje Global del: {porcentajeGlobal}%</strong></h6>
				</Box>
				<Button variant="contained" color="info" onClick={() => handleModalOpen('editPercent')} >
					Editar Porcentaje Global
				</Button>
			</Box>
			<Modal
				open={modalOpen}
				onClose={handleModalClose}
				aria-labelledby="modal-modal-title"
				aria-describedby="modal-modal-description"
				sx={{
					display: 'flex',
					alignItems: 'center',
					justifyContent: 'center',
				}}
			>
				<Paper
					elevation={3}
					sx={{
						width: modalSection === 'editPercent' ? '400px' : '50%',
						padding: 3,
						backgroundColor: '#f5f5f5',
						borderRadius: 2
					}}
				>
					{renderModalContent()}
				</Paper>
			</Modal>
		</Box>
	);
};

export default Dashboard;
