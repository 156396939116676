const calculatePrecioVenta = (precioCompra, precioVenta, porcentajeGlobal = 0) => {
    const formatCurrency = (value) => {
        return new Intl.NumberFormat('es-MX', { style: 'currency', currency: 'MXN' }).format(value);
    };

    // Validaciones para garantizar que los valores son números válidos
    precioCompra = parseFloat(precioCompra) || 0; // Si no es un número válido, se asigna 0
    precioVenta = parseFloat(precioVenta) || 0;
    porcentajeGlobal = parseFloat(porcentajeGlobal) || 0;

    let precioVentaValor = 0;

    if (precioVenta === 0) {
        // Caso: No hay precio de venta, se calcula con porcentajeGlobal
        // precioVentaValor = precioCompra * (1 + porcentajeGlobal / 100);
        precioVentaValor = precioCompra / (1 - (porcentajeGlobal / 100));
    } else if (precioVenta > 1) {
        // Caso: Precio de venta proporcionado es mayor que 1
        precioVentaValor = precioVenta;
    } else if (precioVenta < 1) {
        // Caso: Precio de venta como porcentaje de descuento
        //precioVentaValor = precioCompra * (1 + Math.abs(precioVenta) / 100);
        precioVentaValor = precioCompra / (1 - Math.abs(precioVenta) / 100);
    } else {
        // Caso: Valor no válido
        return {
            precioVentaValor: 0,
            precioVentaFormatted: formatCurrency(0),
            isPrecioVentaMenor: false
        };
    }

    // Formatear el valor final de precio de venta
    const precioVentaFormatted = formatCurrency(precioVentaValor);

    return {
        precioVentaValor,
        precioVentaFormatted,
        isPrecioVentaMenor: precioVentaValor < precioCompra
    };
};

export default calculatePrecioVenta;
