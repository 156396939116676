import React, { useEffect, useState } from 'react';
import { Dialog, Container, Grid, DialogContent, DialogActions, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Checkbox, Button, useTheme } from '@mui/material';

import { useSnackbar } from 'notistack';
import { tokens } from "../../data/tokens";
import { useNavigate } from 'react-router-dom';
import { sendPostData, getDataArray } from '../../system/getData';

import SearchFilters from "../globals/searchFilters";
import { searchParams } from "../../data/search/addArticles";

import { UseAppConfig } from '../../system/config';

const handleResetForm = () => {
    localStorage.removeItem("searchFilters");
};

const AddArticles = ({ data, initValues }) => {
	const formatCurrency = (value) => {
		return new Intl.NumberFormat('es-MX', { style: 'currency', currency: 'MXN' }).format(value);
	};

	const navigate = useNavigate();
	const [rows, setRows] = useState([]);
	const { enqueueSnackbar } = useSnackbar();
	const [loading, setLoading] = useState(true);

	const [selectedItems, setSelectedItems] = useState([]);

	const theme = useTheme();
	const colors = tokens(theme.palette.mode);

	const [open, setOpen] = useState(false);

	const { config, configData } = UseAppConfig();

	const handleCheckboxChange = (id, padreID) => {
		setSelectedItems(prevSelectedItems => {
			const isSelected = prevSelectedItems.some(item => item.id === id);
			if (isSelected) {
				return prevSelectedItems.filter(item => item.id !== id);
			} else {
				return [...prevSelectedItems, { id, padreID }];
			}
		});
	};

	const handleSave = async () => {
		try {
			console.log('Antes de enviar datos');
			const url = `${config.API_BASE_URL}/agrega/articulosCompuesto`;
			const params = { items: selectedItems };
			const result = await sendPostData(url, params);
			enqueueSnackbar('Artículos agregados exitosamente al Compuesto', { variant: 'success' });
			setOpen(false);
			window.location.reload();
		} catch (error) {
			console.error('Error al intentar agregar los Artículos al Compuesto:', error);
			enqueueSnackbar('Error al intentar agregar los Artículos al Compuesto', { variant: 'error' });
		}
	};

	const handleOpen = () => {
		handleResetForm();
		fetchDataTable();
		setOpen(true);
	};

	const handleClose = () => {
		setOpen(false);
	};

    const fetchDataTable = async (params = {}) => {
		try {
			const jsonData = await getDataArray(`${config.API_BASE_URL}/elementos/disponibles`, { id: initValues.id, params });
			const formatData = jsonData.map(row => ({
				...row,
				status: row.status == 1 ? 'Activo' : 'Desactivado'
			}));
			setRows(formatData);
			console.log('Detalles del Registro: ' + JSON.stringify(jsonData));
		} catch (error) {
			console.error('Se ha generado un error a la hora de intentar obtener los datos:', error);
			setRows([]);
		} finally {
			setLoading(false);
		}
	};

	useEffect(() => {
        const savedFilters = localStorage.getItem('searchFilters');
        if (savedFilters) {
            const parsedFilters = JSON.parse(savedFilters);
            fetchDataTable(parsedFilters);
        } else {
            fetchDataTable();
        }
	}, [initValues.id, data.sendData]);

	const handleSearchSubmit = (values) => {
		fetchDataTable(values);
	};

	if (loading) {
		return <div>Cargando...</div>;
	}

	const ColorsDefault = ({ id, nombre, tipo }) => {
		const styleConfig = {
			'Compuesto': { color: 'green', fontSize: '15px', fontWeight: 'normal', cursor: 'normal' },
			'Simple': { color: 'blue', fontSize: '14px', fontWeight: 'normal', cursor: 'normal' },
		};

		const defaultStyle = { color: 'red', fontSize: '14px', fontWeight: 'normal' };
		const appliedStyle = styleConfig[tipo] || defaultStyle;

		return (
			<span style={appliedStyle}>
				{nombre}
			</span>
		);
	};

	return (
		<div>
			<Button
				variant="contained"
				color="primary"
				className="addArticles"
				onClick={handleOpen}
				style={{ marginTop: '100px', marginRight: '0px' }}
			>
				Agregar Artículos
			</Button>
			<Container>
				<Grid container justifyContent="center" className="row-printable">
					<Dialog open={open} onClose={handleClose} maxWidth="md" fullWidth>
						<DialogContent>
							<h5>Listado de Artículos disponibles para este Compuesto:</h5>
							<SearchFilters data={searchParams} onSubmit={handleSearchSubmit} />
							<br /><br />
							<TableContainer>
								<Table>
									<TableHead>
										<TableRow style={{ backgroundColor: colors.grey[900] }}>
											<TableCell className="per5 text-center"><b>Clave</b></TableCell>
											<TableCell className="per5 text-center"><b>Tipo</b></TableCell>
											<TableCell className="per25 text-center"><b>Descripción</b></TableCell>
											<TableCell className="per5 text-center"><b>Familia</b></TableCell>
											<TableCell className="per5 text-center"><b>Cuenta</b></TableCell>
											<TableCell className="per5 text-center"><b>Unidad</b></TableCell>
											<TableCell className="per5 text-center"><b>Precio</b></TableCell>
											<TableCell className="per5 text-center"><b>Estado</b></TableCell>
											<TableCell className="per5 text-center"><b>Seleccionar</b></TableCell>
										</TableRow>
									</TableHead>
									<TableBody>
										{rows.map((row) => (
											<TableRow key={row.id}>
												<TableCell className="text-center">
													<ColorsDefault
														id={row.id}
														nombre={row.clave}
														tipo={row.tipo}
													/>
												</TableCell>
												<TableCell className="text-center">{row.tipo}</TableCell>
												<TableCell className="text-center">{row.descCorta}</TableCell>
												<TableCell className="text-center">{row.familia}</TableCell>
												<TableCell className="text-center">{row.cuenta}</TableCell>
												<TableCell className="text-center">{row.unidad}</TableCell>
												<TableCell className="text-center">{formatCurrency(row.precioCompra)}</TableCell>
												<TableCell className="text-center">{row.status}</TableCell>
												<TableCell className="text-center">
													<Checkbox
														checked={selectedItems.some(item => item.id === row.id)}
														onChange={() => handleCheckboxChange(row.id, initValues.id)}
													/>
												</TableCell>
											</TableRow>
										))}
									</TableBody>
								</Table>
							</TableContainer>
						</DialogContent>
						<DialogActions>
							<Button variant="contained" color="secondary" onClick={handleSave}>
								Agregar Elementos
							</Button>
							<Button variant="contained" color="error" onClick={handleClose}>
								Cerrar
							</Button>
						</DialogActions>
					</Dialog>
				</Grid>
			</Container>
		</div>
	);
};

export default AddArticles;
