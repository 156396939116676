import React, { useState, useEffect, useRef } from 'react';
import { Link, useLocation, useParams, useNavigate } from 'react-router-dom';
import { ExpandLess, ExpandMore, Save, Cancel } from '@mui/icons-material';

import { Grid, Box, useTheme, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Collapse, IconButton, Button, Modal, Switch, Typography, TextField } from '@mui/material';

import { useSnackbar } from 'notistack';

import { DataGrid } from "@mui/x-data-grid";
import { styled } from '@mui/material/styles';

import { tokens } from "../../data/tokens";
import { Header } from "../basics/Header";
import { UseAppConfig } from '../../system/config';
import { sendPostData, getPostData, getDataArray } from '../../system/getData';

import ConfirmationDialog from "../globals/confirmation";
import SearchFilters from "../globals/searchFilters";
import calculatePrecioVenta from "../globals/sellPrice";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBox, faCopy, faPlus, faEdit, faTrash } from '@fortawesome/free-solid-svg-icons';

import { FormRcd } from "../catalogs/addCatalogs";
import { IconsDefault } from "../globals/globalForms";

import { CreateSection, CreateArticle, EditSection, ViewArticle, AddArticles, CreateUser, EditUser } from "../../components/projects/addElements";

import '../../theme/project.css';

const formatCurrency = (value) => {
    return new Intl.NumberFormat('es-MX', { style: 'currency', currency: 'MXN' }).format(value);
};

const handleResetForm = () => {
    localStorage.removeItem("searchFilters");
};

const formatFechaEdicion = (fechaString) => {
    const fecha = new Date(fechaString);
    const opcionesFecha = { year: 'numeric', month: '2-digit', day: '2-digit' };
    const opcionesHora = { hour: '2-digit', minute: '2-digit' };
    const fechaFormateada = fecha.toLocaleDateString('es-MX', opcionesFecha);
    const horaFormateada = fecha.toLocaleTimeString('es-MX', opcionesHora);

    return `${fechaFormateada} ${horaFormateada}`;
};

export const JSONDataTable = ({ data }) => {
    const navigate = useNavigate();
    const handleEdit = (field) => {
        navigate(`/${data.module}/edit/${field}`);
    };
    return (
        <Box m="20px">
            <Header title={data.title} subtitle={data.description} />
            <Paper elevation={3} className="panel">
                <Box mt={4}>
                    <TableContainer component={Paper}>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    {data.tableHeader.map((item, index) => (
                                        <TableCell
                                            key={index}
                                            sx={{ backgroundColor: '#052c65', color: 'white' }}
                                        >
                                            {item.headerName}
                                        </TableCell>
                                    ))}
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {data.tableContent.map((item, index) => (
                                    <TableRow key={index}>
                                        <TableCell
                                            sx={{ backgroundColor: index % 2 === 0 ? '#f1f2f3' : '#ffffff' }}
                                        >
                                            {item.name}
                                        </TableCell>
                                        <TableCell
                                            align="left"
                                            sx={{ backgroundColor: index % 2 === 0 ? '#f1f2f3' : '#ffffff' }}
                                        >
                                            {item.description || 'Sin descripción'}
                                        </TableCell>
                                        <TableCell
                                            align="center"
                                            sx={{ backgroundColor: index % 2 === 0 ? '#f1f2f3' : '#ffffff' }}
                                        >
                                            <Button
                                                variant="contained"
                                                color="warning"
                                                size="small"
                                                sx={{ mr: 1 }}
                                                onClick={() => handleEdit(item.field)}
                                            >
                                                Editar
                                            </Button>
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Box>
            </Paper>
        </Box>
    );
};

export const SimpleDataTable = ({ data }) => {
    const { config, configData } = UseAppConfig();
    const [dataTable, setDataTable] = useState([]);

    const [RcdID, setRcdID] = useState(0);
    const [RcdName, setRcdName] = useState('');

    const [modalSection, setModalSection] = useState(false);
    const [modalOpen, setModalOpen] = useState(false);

    const [OpenConfirm, setConfirmOpen] = useState(false);
    const [ConfirmValue, setConfirmValue] = useState('');


    const { enqueueSnackbar } = useSnackbar();

    const fetchDataTable = async (params = {}) => {
        try {
            const jsonData = await getPostData(`${config.API_BASE_URL}/${data.endpoint}`, { params });

            let formattedData;
            const formatNumber = data.tableHeader.some(rcd => rcd.currency == 1);

            formattedData = jsonData.map(row => ({
                ...row,
                statusValue: row.status,
                status: row.status === 1 ? 'Activado' : 'Desactivado',
                total: formatCurrency(row.total),
                fechaCreacion: formatFechaEdicion(row.fechaCreacion),
                fechaEdicion: formatFechaEdicion(row.fechaEdicion)
            }));

            setDataTable(formattedData);
        } catch (error) {
            console.error('Se ha generado un error a la hora de intentar obtener los datos:', error);
        }
    };

    useEffect(() => {
        const savedFilters = localStorage.getItem('searchFilters');
        if (savedFilters) {
            const parsedFilters = JSON.parse(savedFilters);
            fetchDataTable(parsedFilters);
        } else {
            fetchDataTable();
        }
    }, [data.endpoint]);

    const handleConfirm = async () => {
        try {
            const delUsrID = await getPostData(`${config.API_BASE_URL}/elimina/usuario`, { id: RcdID });
            reloadData();
            handleConfirmClose();
        } catch (error) {
            console.error('Se ha generado un error a la hora de intentar obtener los datos:', error);
        }
        console.log('Confirmado');
    };

    const reloadData = async () => {
        try {
            const savedFilters = localStorage.getItem('searchFilters');
            if (savedFilters) {
                const parsedFilters = JSON.parse(savedFilters);
                fetchDataTable(parsedFilters);
            } else {
                fetchDataTable();
            }
        } catch (error) {
            enqueueSnackbar('Error al recargar los datos', { variant: 'error' });
        }
    };

    const handleModalOpen = (section, rcdID) => {
        setModalSection(section);
        setRcdID(rcdID);
        setModalOpen(true);
    };

    const handleModalClose = () => {
        setModalOpen(false);
    };

    const handleConfirmsOpen = (params) => {
        setRcdID(params.id);
        setRcdName(params.RcdName);
        setConfirmValue(params.ConfirmValue);
        setConfirmOpen(true);
    };

    const handleConfirmClose = () => {
        setConfirmOpen(false);
    };

    const renderModalContent = () => {
        switch (modalSection) {
            case 'addUser':
                return <CreateUser reloadData={reloadData} setModalOpen={setModalOpen} />;
            case 'editUser':
                return <EditUser userID={RcdID} reloadData={reloadData} setModalOpen={setModalOpen} />;
            default:
                return null;
        }
    };

    return (
        <Box m="20px">
            <Header title={data.title} subtitle={data.description} />
            <Paper elevation={3} className="panel">
                <Box mt={4}>
                    {data.module === 'users' && (
                        <Box
                            key="Agregar Nuevo"
                            style={{ position: 'absolute', top: '50px', right: '60px', zIndex: 100 }}
                        >
                            <Button
                                style={{ marginLeft: '10px' }}
                                variant="contained"
                                color="info"
                                onClick={() => handleModalOpen('addUser')}
                            >
                                Agregar Nuevo Usuario
                            </Button>
                        </Box>
                    )}
                    <TableContainer component={Paper}>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    {data.tableHeader.map((header, index) => (
                                        <TableCell
                                            key={index}
                                            sx={{
                                                backgroundColor: '#052c65',
                                                color: 'white',
                                                textAlign: header.field === 'total' ? 'right' : (header.align || 'center'),
                                                flex: header.flex || 1,
                                            }}
                                        >
                                            {header.headerName}
                                        </TableCell>
                                    ))}
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {dataTable.map((row, rowIndex) => (
                                    <TableRow key={rowIndex}>
                                        {data.tableHeader.map((header, cellIndex) => (
                                            <TableCell
                                                key={cellIndex}
                                                sx={{
                                                    backgroundColor: rowIndex % 2 === 0 ? '#f1f2f3' : '#ffffff',
                                                    textAlign: header.field === 'total' ? 'right' : (header.align || 'center'),
                                                    flex: header.flex || 1,
                                                    cursor: data.module === 'projects' ? 'pointer' : (header.align || 'normal'),
                                                }}
                                                onClick={
                                                    data.module === 'projects'
                                                        ? () => {
                                                            const targetUrl = `${data.module}/details/${row.id}`;
                                                            window.location.href = targetUrl;
                                                        }
                                                        : undefined
                                                }
                                            >
                                                {header.field === 'acciones' ? (
                                                    Object.keys(header.botons).map((buttonKey) => {
                                                        const button = header.botons[buttonKey];
                                                        return (
                                                            <Button
                                                                key={buttonKey}
                                                                variant="contained"
                                                                color={button.class}
                                                                size="small"
                                                                onClick={() => {
                                                                    if (button.label === 'Editar') {
                                                                        handleModalOpen('editUser', row.id);
                                                                    } else if (button.label === 'Eliminar') {
                                                                        handleConfirmsOpen({
                                                                            id: row.id,
                                                                            ConfirmValue: 6,
                                                                            RcdName: row.nombre
                                                                        });
                                                                    }
                                                                }}
                                                                style={{
                                                                    minWidth: '25px',
                                                                    height: '22px',
                                                                    margin: '1px',
                                                                    borderRadius: '0px',
                                                                    padding: '0px',
                                                                }}
                                                            >
                                                                {button.label === 'Editar' && <FontAwesomeIcon icon={faEdit} />}
                                                                {button.label === 'Eliminar' && <FontAwesomeIcon icon={faTrash} />}
                                                            </Button>
                                                        );
                                                    })
                                                ) : (
                                                    row[header.field]
                                                )}
                                            </TableCell>
                                        ))}
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Box>
            </Paper>
            <Modal
                open={modalOpen}
                onClose={handleModalClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
                sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                }}
            >
                <Paper
                    elevation={3}
                    sx={{
                        width: '900px',
                        padding: 3,
                        backgroundColor: '#f5f5f5',
                        borderRadius: 2
                    }}
                >
                    {renderModalContent()}
                </Paper>
            </Modal>
            <ConfirmationDialog
                open={OpenConfirm}
                onClose={handleConfirmClose}
                onConfirm={handleConfirm}
                statusValue={ConfirmValue}
                keyValue={RcdName}
            />
        </Box>
    );
};

export const DataTablePerID = ({ data, section }) => {
    const { id } = useParams();
    const { config } = UseAppConfig();
    const [dataTable, setDataTable] = useState({});

    const theme = useTheme();
    const colors = tokens(theme.palette.mode);

    const [isLoading, setIsLoading] = useState(true);
    const [error, setError] = useState(null);

    const [modalOpen, setModalOpen] = useState(false);
    const [modalSectionName, setModalSectionName] = useState('');
    const [modalSectionValue, setModalSectionValue] = useState('');
    const [modalSection, setModalSection] = useState(false);

    const handleModalOpen = (catalogValue, catalogName, section) => {
        setModalSectionValue(catalogValue);
        setModalSectionName(catalogName);
        setModalSection(section);
        setModalOpen(true);
    };

    const handleModalClose = () => {
        setModalOpen(false);
    };

    const getColumns = (catalog) => {
        if (!catalog || !catalog.columns) return [];
        const columns = [
            ...(catalog.columns || []),
            {
                field: 'edit',
                headerName: 'Editar',
                width: 100,
                headerAlign: 'center',
                align: 'center',
                renderCell: (params) => (
                    <Box display="flex" justifyContent="center" width="100%">
                        <Button
                            style={{ width: '10px', minWidth: '10px' }}
                            variant="contained"
                            color="warning"
                            onClick={() => handleModalOpen(id, params.row.id, 'edit')}
                        >
                            <FontAwesomeIcon icon={faEdit} />
                        </Button>
                    </Box>
                ),
            },
        ];
        return columns;
    };

    const fetchDataTable = async (field) => {
        try {
            const jsonData = await getPostData(`${config.API_BASE_URL}/${data.endpoint}/${field}`, {});

            let formattedData = jsonData.map(row => ({
                ...row,
                status: row.status === 1 ? 'Activo' : 'Desactivado',
                module: row.tipo === 'Simple' ? 'articles' : 'compounds'
            }));

            setDataTable(prevState => ({ ...prevState, [field]: formattedData }));
        } catch (error) {
            console.error('Error al obtener los datos:', error);
            setError(`No ha sido posible obtener los datos del registro: ${field}`);
        }
    };

    useEffect(() => {
        const fetchData = async () => {
            if (id) {
                setIsLoading(true);
                await fetchDataTable(id);
                setIsLoading(false);
            }
        };
        fetchData();
    }, [id]);

    if (isLoading) {
        return <div>Cargando...</div>;
    }

    if (error) {
        return <div>{error}</div>;
    }

    return (
        <Box m="0px">
            <Box
                maxWidth="100%"
                mx="auto"
                mt="-25px"
                sx={{
                    "& .MuiTable-root": { border: "none" },
                    "& .MuiTableCell-root": { borderBottom: "none" },
                    "& .MuiTableHead-root": { backgroundColor: colors.primary[500], borderBottom: "none" },
                    "& .MuiTableCell-head": { color: colors.grey[900] },
                    "& .MuiTableContainer-root": { backgroundColor: colors.primary[900] },
                }}
            >
                <Box display="flex" justifyContent="space-between" alignItems="center" mb={2}>
                    <Typography variant="h6">Registros del Catálogo</Typography>
                    <Button
                        variant="contained"
                        color="info"
                        onClick={() => handleModalOpen(id, section.name, 'new')}
                    >
                        <FontAwesomeIcon icon={faPlus} /> Agregar
                    </Button>
                </Box>
                <DataGrid
                    rows={dataTable[id] || []}
                    columns={getColumns(section) || []}
                    getRowClassName={(params) =>
                        params.indexRelativeToCurrentPage % 2 === 0 ? 'fila-par' : 'fila-impar'
                    }
                    disableColumnMenu
                    disableColumnSelector
                    hideFooter
                    className="datos-tabla"
                    autoHeight
                    sx={{
                        '& .MuiDataGrid-columnHeaders': {
                            backgroundColor: '#052c65',
                            color: '#fff',
                        }
                    }}
                />
            </Box>
            <Modal
                open={modalOpen}
                onClose={handleModalClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
                sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                }}
            >
                <Paper
                    elevation={3}
                    sx={{
                        width: '100%',
                        maxWidth: '700px',
                        margin: '0 auto',
                        padding: 2,
                    }}
                >
                    {modalSection === 'new' ? (
                        <FormRcd secValue={modalSectionValue} secName={modalSectionName} />
                    ) : (
                        <FormRcd secValue={modalSectionValue} regID={modalSectionName} />
                    )}
                </Paper>
            </Modal>
        </Box>
    );
};

export const ExpandableDataTable = ({ data }) => {
    const tableContainerRef = useRef(null);
    const { enqueueSnackbar } = useSnackbar();

    const [expandedRows, setExpandedRows] = useState(new Set());
    const [dataTable, setDataTable] = useState([]);
    const [loading, setLoading] = useState(true);

    const { config, configData } = UseAppConfig();
    const [totalProjectCost, setTotalProjectCost] = useState(0);

    const [modalOpen, setModalOpen] = useState(false);
    const [modalSection, setModalSection] = useState(false);

    const calculateTotals = (node, priceField = 'precioVenta') => {
        let total = 0;
        if (node.art && node.art.length > 0) {
            total += node.art.reduce((sum, art) => {
                const artPrice = parseFloat(art[priceField]?.replace(',', '')) || 0;
                return sum + artPrice;
            }, 0);
        }

        if (node.cat && node.cat.length > 0) {
            total += node.cat.reduce((sum, subCategory) => sum + calculateTotals(subCategory, priceField), 0);
        }

        node[priceField] = total;
        return total;
    };

    const buildHierarchy = (flatData) => {
        const dataMap = {};
        const result = [];

        if (data.module === 'projects') {
            flatData.forEach(item => {
                const newPrice = parseFloat(item.precioVenta.replace(',', '')) || 0;
                dataMap[item.id] = { ...item, cat: [], art: item.art || [], precioVenta: newPrice };
            });

            flatData.forEach(item => {
                if (item.parent_id === null || item.parent_id === 'null') {
                    result.push(dataMap[item.id]);
                } else if (dataMap[item.parent_id]) {
                    dataMap[item.parent_id].cat.push(dataMap[item.id]);
                } else {
                    console.warn(`Nodo con ID ${item.id} tiene un parent_id (${item.parent_id}) no válido.`);
                }
            });

            result.forEach(rootNode => calculateTotals(rootNode));
        } else {
            flatData.forEach(item => {
                dataMap[item.id] = { ...item, cat: [], art: item.art || [] };
            });

            flatData.forEach(item => {
                if (item.parent_id === null || item.parent_id === 'null') {
                    result.push(dataMap[item.id]);
                } else if (dataMap[item.parent_id]) {
                    dataMap[item.parent_id].art.push(dataMap[item.id]);
                } else {
                    console.warn(`Nodo con ID ${item.id} tiene un parent_id (${item.parent_id}) no válido.`);
                }
            });
        }
        return result;
    };

    const fetchDataTable = async (params = {}) => {
        setLoading(true);
        console.log('Los Filtros de búsqueda son: ' + JSON.stringify(params));
        try {
            const response = await getDataArray(`${config.API_BASE_URL}/${data.contentList}`, { proyID: data.id, params });
            const porcentajeGlobal = configData ? configData.porcentajeGlobal : 0;

            const formattedData = response.map(row => {
                const { precioVentaValor, precioVentaFormatted, isPrecioVentaMenor } = calculatePrecioVenta(
                    row.precio_compra,
                    row.precio_venta,
                    porcentajeGlobal
                );

                return {
                    ...row,
                    precio_compra: formatCurrency(row.precio_compra),
                    precio_venta: formatCurrency(precioVentaValor),
                    isPrecioVentaMenor
                };
            });

            const hierarchicalData = buildHierarchy(formattedData);
            setDataTable(hierarchicalData);

            if (data.module === "projects") {
                const total = hierarchicalData.reduce((sum, category) => sum + category.precioVenta, 0);
                setTotalProjectCost(total);
                actualizaPrecioProyecto(total);
            }
        } catch (error) {
            console.error('Error fetching categories:', error);
            enqueueSnackbar('Error al cargar la información de los artículos', { variant: 'error' });
        } finally {
            setLoading(false);
        }
    };

    const reloadData = async () => {
        try {
            const savedFilters = localStorage.getItem('searchFilters');
            if (savedFilters) {
                const parsedFilters = JSON.parse(savedFilters);
                fetchDataTable(parsedFilters);
            } else {
                fetchDataTable();
            }
        } catch (error) {
            enqueueSnackbar('Error al recargar los datos', { variant: 'error' });
        }
    };

    const actualizaPrecioProyecto = async (newPrice) => {
        try {
            try {
                const totalProyecto = await sendPostData(`${config.API_BASE_URL}/actualiza/precioProyecto`, { proyID: data.id, newPrice });
            } catch (error) {
                console.error('Error al consultar el nuevo Precio de Venta:', error);
            }
        } catch (error) {
            console.error('Error al actualizar la cantidad de este artículo:', error);
        }
    };

    const toggleRowExpansion = (id) => {
        setExpandedRows((prev) => {
            const newExpandedRows = new Set(prev);
            if (newExpandedRows.has(id)) {
                newExpandedRows.delete(id);
            } else {
                newExpandedRows.add(id);
            }
            return newExpandedRows;
        });
    };

    useEffect(() => {
        const savedFilters = localStorage.getItem('searchFilters');
        if (savedFilters) {
            const parsedFilters = JSON.parse(savedFilters);
            fetchDataTable(parsedFilters);
        } else {
            fetchDataTable();
        }
    }, [data.id]);

    const handleSearchSubmit = (values) => {
        fetchDataTable(values);
    };

    const handleModalOpen = (section) => {
        setModalSection(section);
        setModalOpen(true);
    };

    const handleModalClose = () => {
        setModalOpen(false);
    };

    const renderModalContent = () => {
        switch (modalSection) {
            case 'addCategory':
                return <CreateSection proyID={data.id} reloadData={reloadData} setModalOpen={setModalOpen} />;
            case 'createArticle':
                return <CreateArticle reloadData={reloadData} setModalOpen={setModalOpen} />;
            default:
                return null;
        }
    };

    return (
        <>
            <Box mt={4}>
                {data.searchParams?.length > 0 ? (
                    <SearchFilters data={data.searchParams} onSubmit={handleSearchSubmit} />
                ) : (
                    null
                )}
                <Box>
                    {data.module === 'projects' ? (
                        <>
                            <Box
                                sx={{
                                    position: 'relative',
                                    display: 'flex',
                                    justifyContent: 'flex-end',
                                    alignItems: 'center',
                                    width: '100%',
                                    marginTop: '-110px',
                                    marginBottom: '70px',
                                }}
                            >
                                <Typography variant="h5" gutterBottom>
                                    Precio de Venta Total: <strong>{formatCurrency(totalProjectCost)}</strong>
                                </Typography>
                            </Box>
                            <Box display="flex" alignItems="center" justifyContent="flex-end">
                                <Button
                                    variant="contained"
                                    color="success"
                                    onClick={() => handleModalOpen('addCategory')}
                                >
                                    Crear Nueva Categoría
                                </Button>
                            </Box>
                            <Box display="flex" alignItems="center" justifyContent="space-between">
                                <Typography variant="h4" gutterBottom>
                                    Categorías incluidas en el Proyecto:
                                </Typography>
                            </Box>
                        </>
                    ) : data.module === 'articles' ? (
                        <>
                            <Box display="flex" alignItems="center" justifyContent="flex-end" mt={2}>
                                <Button
                                    variant="contained"
                                    color="success"
                                    onClick={() => handleModalOpen('createArticle')}
                                >
                                    Crear Nuevo Artículo
                                </Button>
                            </Box>
                            <Box display="flex" alignItems="center" justifyContent="space-between">
                                <Typography variant="h4" gutterBottom>
                                    Seleccione la opción que desee para editar la información de cualquier artículo:
                                </Typography>
                            </Box>
                        </>
                    ) : null}
                </Box>
                <Box mt={4}>
                    <TableContainer ref={tableContainerRef} component={Paper}>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    {data.tableHeader.map((header, index) => (
                                        <TableCell
                                            key={index}
                                            sx={{
                                                backgroundColor: '#052c65',
                                                color: 'white',
                                                width: header.width,
                                                textAlign: header.field === 'total' ? 'right' : header.align || 'center',
                                            }}
                                        >
                                            {header.headerName}
                                        </TableCell>
                                    ))}
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {dataTable.length > 0 ? (
                                    dataTable.map((row, index) => (
                                        <ExpandableData
                                            key={index}
                                            data={row}
                                            configData={data}
                                            level={0}
                                            reloadData={reloadData}
                                            expandedRows={expandedRows}
                                            toggleRowExpansion={toggleRowExpansion}
                                        />
                                    ))
                                ) : (
                                    <TableRow>
                                        <TableCell colSpan={data.tableHeader ? data.tableHeader.length : 1} align="center">
                                            No hay datos para mostrar.
                                        </TableCell>
                                    </TableRow>
                                )}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Box>
                <Modal
                    open={modalOpen}
                    onClose={handleModalClose}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                    sx={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                    }}
                >
                    <Paper
                        elevation={3}
                        sx={{
                            width: data.module === 'projects' ? '600px' : data.module === 'articles' ? '900px' : '50%',
                            padding: 3,
                            backgroundColor: '#f5f5f5',
                            borderRadius: 2
                        }}
                    >
                        {renderModalContent()}
                    </Paper>
                </Modal>
            </Box>
        </>
    );
};

const ExpandableData = ({ data, configData, level = 0, reloadData, expandedRows, toggleRowExpansion }) => {
    const { enqueueSnackbar } = useSnackbar();
    const navigate = useNavigate();

    const { config } = UseAppConfig();

    const [isAddingSubcategory, setIsAddingSubcategory] = useState(false);
    const [newSubcategoryName, setNewSubcategoryName] = useState('');

    const [artID, setArtID] = useState(false);
    const [modalOpen, setModalOpen] = useState(false);
    const [modalSection, setModalSection] = useState(false);

    const [OpenConfirm, setConfirmOpen] = useState(false);
    const [ConfirmRcdID, setConfirmRcdID] = useState('');
    const [ConfirmValue, setConfirmValue] = useState('');
    const [RcdName, setRcdName] = useState('');

    const [isExpanded, setIsExpanded] = useState(false);

    const commonCellStyle = {
        textAlign: 'center',
        width: '10%',
    };

    const handleToggle = () => {
        setIsExpanded(!isExpanded);
    };

    const [sectionFormValues, setSectionFormValues] = useState({
        proyID: configData.id,
        secTitle: newSubcategoryName,
        secDesc: '',
        categoria_padre_id: data.id
    });

    const ColorsDefault = ({ level, id, nombre, tipo }) => {
        const styleConfig = {
            'Categoría': { color: 'black', fontWeight: level === 0 ? 'bold' : level === 1 ? 'normal' : 'normal', textTransform: 'uppercase' },
            'Compuesto': { color: 'green', fontWeight: 'normal', cursor: 'pointer' },
            'Simple': { color: 'blue', fontWeight: 'normal', cursor: 'pointer' },
        };

        const defaultStyle = { color: 'red', fontWeight: 'normal' };
        const appliedStyle = styleConfig[tipo] || defaultStyle;

        return (
            <span
                style={appliedStyle}
                onClick={tipo !== 'Categoría' ? () => handleRcdEdit('viewArt', id, data.nombre) : undefined}
            >
                {nombre}
            </span>
        );
    };
    const botonesConfig = {
        articles: {
            'Compuesto': [
                { name: 'ediComptArt', label: 'Editar', color: 'warning', icon: faEdit, imagen: '' },
                { name: 'delCompArt', label: 'Eliminar', color: 'error', icon: faTrash, imagen: '' },
            ],
            'Simple': [
                { name: 'editSimpArt', label: 'Editar', color: 'warning', icon: faEdit, imagen: '' },
                { name: 'delSimpArt', label: 'Eliminar', color: 'error', icon: faTrash, imagen: '' },
            ],
        },
        projects: {
            'Categoría': [
                { name: 'subCat', label: 'Agregar Sub-Categoría', color: 'success', icon: faBox, imagen: '' },
                { name: 'editCat', label: 'Editar', color: 'warning', icon: faEdit, imagen: '' },
                { name: 'addArt', label: 'Agregar Artículos', color: 'info', icon: faCopy, imagen: '' },
                { name: 'delCat', label: 'Eliminar', color: 'error', icon: faTrash, imagen: '' },
            ],
            'Compuesto': [
                { name: 'delArt', label: 'Eliminar Artículo Compuesto', color: 'error', icon: faTrash, imagen: '' },
            ],
            'Simple': [
                { name: 'delArt', label: 'Eliminar Artículo Simple', color: 'error', icon: faTrash, imagen: '' },
            ],
        },
    };

    // Condición para mostrar botones solo cuando el tipo sea "Simple" y el nivel sea 0
    const botones =
        configData?.module === 'projects'
            ? (
                data?.tipo === 'Categoría' ||
                    data?.tipo === 'Sub Categoría' ||
                    (data?.tipo !== 'Categoría' && data?.tipo !== 'Sub Categoría' && data?.parent_id === null)
                    ? botonesConfig.projects[data.tipo] || []
                    : []
            )
            : botonesConfig[configData?.module]?.[data?.tipo] || [];

    const handleRcdEdit = async (thisType, thisID, thisName) => {
        switch (configData.module) {
            case 'articles':
                switch (thisType) {
                    case 'ediComptArt':
                        navigate(`/compounds/details/${thisID}`);
                        break;
                    case 'editSimpArt':
                        navigate(`details/${thisID}`);
                        break;
                    case 'delSimpArt':
                        handleConfirmsOpen({ id: thisID, ConfirmValue: 4, RcdName: thisName });
                        break;
                    case 'delCompArt':
                        handleConfirmsOpen({ id: thisID, ConfirmValue: 5, RcdName: thisName });
                        break;
                    case 'viewArt':
                        setArtID(thisID);
                        setModalSection('viewArticle');
                        setModalOpen(true);
                        break;
                    default:
                        console.warn('Tipo no reconocido:', thisType);
                        break;
                }
                break;
            case 'projects':
                switch (thisType) {
                    case 'subCat':
                        setIsAddingSubcategory(true);
                        break;
                    case 'editCat':
                        setModalSection('editCategory');
                        setModalOpen(true);
                        break;
                    case 'addArt':
                        setModalSection('addArticles');
                        setModalOpen(true);
                        break;
                    case 'delCat':
                        handleConfirmsOpen({ id: thisID, ConfirmValue: 2, RcdName: thisName });
                        break;
                    case 'delArt':
                        handleConfirmsOpen({ id: thisID, ConfirmValue: 3, RcdName: thisName });
                        break;
                    case 'viewArt':
                        setArtID(thisID);
                        setModalSection('viewArticle');
                        setModalOpen(true);
                        break;
                    default:
                        console.warn('Tipo no reconocido:', thisType);
                        break;
                }
                break;
        }
    };

    const renderModalContent = () => {
        switch (modalSection) {
            case 'addCategory':
                return <CreateSection proyID={data.id} />;
            case 'editCategory':
                return <EditSection data={data.id} reloadData={reloadData} setModalOpen={setModalOpen} />;
            case 'viewArticle':
                return configData.module === "projects" ? (
                    <ViewArticle id={artID} catID={data.catID} precioVenta={formatCurrency(data.precioVenta)} section={configData.module} />
                ) : (
                    <ViewArticle id={artID} precioVenta={data.precio_venta} section={configData.module} />
                );
            case 'addArticles':
                handleResetForm();
                return <AddArticles proyID={configData.id} catID={data.id} catName={data.nombre} reloadData={reloadData} setModalOpen={setModalOpen} />;
            default:
                return null;
        }
    };

    const handleModalClose = () => {
        setModalOpen(false);
    };

    const handleConfirmsOpen = (params) => {
        setConfirmRcdID(params.id);
        setRcdName(params.RcdName);
        setConfirmValue(params.ConfirmValue);
        setConfirmOpen(true);
    };

    const handleConfirmClose = () => {
        setConfirmOpen(false);
    };

    const handleSaveSubcategory = async () => {
        if (newSubcategoryName.trim()) {
            setIsAddingSubcategory(false);
            const updatedValues = {
                ...sectionFormValues,
                secTitle: newSubcategoryName,
            };
            await onAddSubcategory(updatedValues);
        }
    };

    const handleConfirm = async () => {
        try {
            switch (configData.module) {
                case 'articles':
                    console.log('El tipo es: ' + ConfirmValue);

                    switch (ConfirmValue) {
                        case 4:
                        case 5:
                            var url = `${config.API_BASE_URL}/elimina/articulo`;
                            var params = { id: ConfirmRcdID };
                            break;
                    }
                    enqueueSnackbar('Artículo retirado exitosamente', { variant: 'success' });
                    break;
                case 'projects':
                    switch (ConfirmValue) {
                        case 2:
                            var url = `${config.API_BASE_URL}/retira/categoriaProyecto`;
                            var params = { proyID: configData.id, catID: ConfirmRcdID };
                            break;
                        case 3:
                            var url = `${config.API_BASE_URL}/retira/articuloCategoria`;
                            var params = { id: ConfirmRcdID };
                            break;
                    }
                    enqueueSnackbar('Artículo retirado exitosamente', { variant: 'success' });
                    break;
            }
            const result = await sendPostData(url, params);
            setConfirmOpen(false);
            console.log('Preparando para Refrescar');
            reloadData();
        } catch (error) {
            enqueueSnackbar('Error al intentar retirar este artículo', { variant: 'error' });
            setConfirmOpen(false);
        }
        console.log('Confirmado');
    };

    const onAddSubcategory = async (values) => {
        try {
            const result = await sendPostData(`${config.API_BASE_URL}/crea/categoria`, values);
            enqueueSnackbar('Sección Creada Exitosamente', { variant: 'success' });
            reloadData();
        } catch (error) {
            console.error('Error al crear la subcategoría:', error);
            enqueueSnackbar('Ha ocurrido un error al intentar crear la nueva sección', { variant: 'error' });
        }
    };

    useEffect(() => {
        setSectionFormValues((prevValues) => ({
            ...prevValues,
            secTitle: newSubcategoryName,
        }));
    }, [newSubcategoryName]);

    const handleCancelAddSubcategory = () => {
        setIsAddingSubcategory(false);
        setNewSubcategoryName('');
    };

    const handleStatusToggle = async (id, currentStatus) => {
        try {
            const url = `${config.API_BASE_URL}/edita/estado`;
            const newStatus = currentStatus === 'Activado' ? 0 : 1;

            const params = { id, status: newStatus, section: 'articulo' };
            const result = await sendPostData(url, params);

            reloadData();
        } catch (error) {
            console.error('Error al cambiar estado:', error);
            enqueueSnackbar('Error al cambiar el estado', { variant: 'error' });
        }
    };

    let modalWidth;
    let modalHeight;
    switch (modalSection) {
        case 'viewArticle':
            modalWidth = '950px';
            modalHeight = '600px';
            break;
        case 'editCategory':
            modalWidth = '600px';
            modalHeight = '330px';
            break;
        case 'addArticles':
            modalWidth = '900px';
            modalHeight = '900px';
            break;
        default:
            modalWidth = '700px';
            modalHeight = '400px';
    }

    return (
        <>
            {data && (
                <TableRow>
                    <TableCell
                        sx={{
                            borderBottom: 'none',
                            position: 'relative',
                            backgroundColor: '#f5f5f5',
                            paddingLeft: `${level * 2}%`,
                            width: '20%'
                        }}
                    //onClick={handleToggle}
                    >
                        <Box sx={{ display: 'flex', alignItems: 'center', height: 15 }}>
                            <Typography sx={{ display: 'flex', alignItems: 'center', fontSize: '14px' }}>
                                <Box component="span"
                                    sx={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        marginLeft: '20px'
                                    }}>
                                    <IconsDefault tipo={data.tipo} icon={data.icono} />
                                    <span style={{ marginLeft: '10px', marginRight: '1px', fontSize: '20px' }}></span>
                                    <ColorsDefault
                                        level={level}
                                        id={configData.module === "projects" ? data.artID : data.id}
                                        nombre={data.nombre}
                                        tipo={data.tipo}
                                    />
                                </Box>
                            </Typography>
                            {(data.art && data.art.length > 0) || (data.cat && data.cat.length > 0) ? (
                                <IconButton
                                    sx={{
                                        marginLeft: '-6px',
                                        marginRight: -1,
                                        cursor: 'pointer'
                                    }}
                                    onClick={handleToggle}
                                    aria-label={isExpanded ? 'Colapsar' : 'Expandir'}
                                >
                                    {isExpanded ? <ExpandLess /> : <ExpandMore />}
                                </IconButton>
                            ) : (
                                <span
                                    style={{
                                        marginLeft: level === 0 ? '10px' : level === 1 ? '-10px' : '-5px',
                                        marginRight: '15px',
                                        fontSize: '20px'
                                    }}
                                >
                                    &nbsp;
                                </span>
                            )}
                        </Box>
                    </TableCell>

                    {Object.keys(data)
                        .filter((field) =>
                            !(configData.module === 'projects'
                                ? [
                                    'id',
                                    'artID',
                                    'catID',
                                    'padreID',
                                    'parent_id',
                                    'nombre',
                                    'tipo',
                                    'icono',
                                    'status',
                                    'cat',
                                    'art',
                                    'precio_compra',
                                    'precio_venta',
                                    'isPrecioVentaMenor'
                                ]
                                : [
                                    'id',
                                    'padreID',
                                    'parent_id',
                                    'nombre',
                                    'tipo',
                                    'icono',
                                    'cat',
                                    'art',
                                    'isPrecioVentaMenor'
                                ]
                            ).includes(field)
                        )
                        .map((field, index) => (
                            <TableCell
                                key={`cell-${index}-${field}`}
                                sx={{
                                    ...commonCellStyle,
                                    textAlign: index === 1 ? 'left' : 'center',
                                }}
                                className={field === 'precio_venta' && data.isPrecioVentaMenor ? 'red-text' : ''}
                            >
                                {(() => {
                                    switch (field) {
                                        case 'precioCompra':
                                            return formatCurrency(data[field]);
                                        case 'precioVenta':
                                            return formatCurrency(data[field]);
                                        case 'status':
                                            const shouldRenderSwitch =
                                                configData.module === "projects" ||
                                                (configData.module === "articles" && level == 0);

                                            if (shouldRenderSwitch) {
                                                return (
                                                    <Switch
                                                        checked={data[field] === 'Activado'}
                                                        onChange={() => handleStatusToggle(data.id, data[field])}
                                                        color="primary"
                                                    />
                                                );
                                            }
                                        default:
                                            return data[field];
                                    }
                                })()}
                            </TableCell>
                        ))}

                    <TableCell sx={{ textAlign: 'center', width: '10%' }}>
                        {botones
                            .filter((field) => {
                                if (configData.module === "projects") {
                                    return true;
                                }
                                if (configData.module === "articles" && level == 0) {
                                    return true;
                                }
                                return false;
                            })
                            .map((button, index) => (
                                <Button
                                    key={index}
                                    variant="contained"
                                    color={button.color}
                                    size="small"
                                    title={button.label}
                                    onClick={() => handleRcdEdit(button.name, data.id, data.nombre)}
                                    style={{
                                        minWidth: '25px',
                                        height: '22px',
                                        margin: '1px',
                                        borderRadius: '0px',
                                        padding: '0px',
                                    }}
                                >
                                    {button.imagen ? (
                                        <img
                                            src={`/assets/icons/${button.imagen}`}
                                            width="12px"
                                            alt={button.label}
                                            style={{ bottom: '-1px', position: 'relative' }}
                                        />
                                    ) : (
                                        <FontAwesomeIcon icon={button.icon} style={{ fontSize: '12px' }} />
                                    )}
                                </Button>
                            ))}
                    </TableCell>
                </TableRow >
            )}

            {
                isAddingSubcategory && (
                    <TableRow sx={{ '& > *': { padding: '4px 8px' } }}>
                        <TableCell colSpan={10} sx={{ paddingLeft: `${(level + 1) * 2.5}%` }}>
                            <Typography>Agregar Nueva Sub Categoría:</Typography>
                            <Box sx={{ display: 'flex', alignItems: 'center', marginTop: 1 }}>
                                <TextField
                                    size="small"
                                    label="Nombre de la Subcategoría"
                                    placeholder="Nombre de la subcategoría"
                                    variant="outlined"
                                    value={newSubcategoryName}
                                    onChange={(e) => setNewSubcategoryName(e.target.value)}
                                    sx={{ marginRight: 1, flexGrow: 1 }}
                                />
                                <Button
                                    variant="contained"
                                    color="primary"
                                    size="small"
                                    onClick={handleSaveSubcategory}
                                    startIcon={<Save />}
                                >
                                    Guardar
                                </Button>
                                <Button
                                    variant="contained"
                                    color="error"
                                    size="small"
                                    onClick={handleCancelAddSubcategory}
                                    startIcon={<Cancel />}
                                    sx={{ marginLeft: 1 }}
                                >
                                    Cancelar
                                </Button>
                            </Box>
                        </TableCell>
                    </TableRow>
                )
            }

            {/* Subniveles - Categorías */}
            {data.cat && data.cat.length > 0 && (
                <TableRow>
                    <TableCell colSpan={10} sx={{ padding: 0 }}>
                        <Collapse in={isExpanded} timeout="auto" unmountOnExit>
                            <Table size="small">
                                <TableBody>
                                    {data.cat.map((child, index) => (
                                        <ExpandableData
                                            key={index}
                                            data={child}
                                            configData={configData}
                                            level={level + 1}
                                            reloadData={reloadData}
                                            expandedRows={expandedRows}
                                            toggleRowExpansion={toggleRowExpansion}
                                        />
                                    ))}
                                </TableBody>
                            </Table>
                        </Collapse>
                    </TableCell>
                </TableRow>
            )}

            {/* Subniveles - Artículos */}
            {data.art && data.art.length > 0 && (
                <TableRow>
                    <TableCell colSpan={10} sx={{ padding: 0 }}>
                        <Collapse in={isExpanded} timeout="auto" unmountOnExit>
                            <Table size="small">
                                <TableBody>
                                    {data.art.map((child, index) => (
                                        <ExpandableData
                                            key={index}
                                            data={child}
                                            configData={configData}
                                            level={level + 1}
                                            reloadData={reloadData}
                                            expandedRows={expandedRows}
                                            toggleRowExpansion={toggleRowExpansion}
                                        />
                                    ))}
                                </TableBody>
                            </Table>
                        </Collapse>
                    </TableCell>
                </TableRow>
            )}

            <Modal
                open={modalOpen}
                onClose={handleModalClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
                sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                }}
            >
                <Paper
                    elevation={3}
                    sx={{
                        width: modalWidth,
                        maxHeight: modalHeight,
                        overflowY: 'auto',
                        padding: 3,
                        backgroundColor: '#f5f5f5',
                        borderRadius: 2
                    }}
                >
                    {renderModalContent()}
                </Paper>
            </Modal>
            <ConfirmationDialog
                open={OpenConfirm}
                onClose={handleConfirmClose}
                onConfirm={handleConfirm}
                statusValue={ConfirmValue}
                keyValue={RcdName}
            />
        </>
    );
}