import React, { useEffect, useState } from 'react';
import { Link, useParams, useNavigate } from 'react-router-dom';
import { Box, Modal, TextField, Tabs, Tab, Grid, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Button, useTheme, FormControlLabel, Radio, RadioGroup } from '@mui/material';

import { Formik } from "formik";
import { useSnackbar } from 'notistack';
import { tokens } from "../../data/tokens";
import { sendPostData, getPostData, getDataArray } from '../../system/getData';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash, faEdit } from '@fortawesome/free-solid-svg-icons';

import { Header } from "../basics/Header";
import { UseAppConfig } from '../../system/config';

import AddArticles from "./../compounds/addArticles";
import ConfirmationDialog from "../globals/confirmation";

import { CreateSection, ViewArticle, EditGlobalPercent } from "../../components/projects/addElements";

import { IconsDefault } from "../globals/globalForms";
import { ExpandableDataTable } from "../../components/globals/newDataTable";


import { GeneraFormularioSimple } from "../../components/globals/globalForms";
import { editArticle } from "../../data/forms/articles";

import * as yup from "yup";

export const GetArticlesList = ({ data }) => {
	const { configData } = UseAppConfig();
	const [modalOpen, setModalOpen] = useState(false);
	const [modalSection, setModalSection] = useState(false);

	const porcentajeGlobal = configData ? configData.porcentajeGlobal : 0;

	const handleModalOpen = (section) => {
		setModalSection(section);
		setModalOpen(true);
	};

	const handleModalClose = () => {
		setModalOpen(false);
	};

	const renderModalContent = () => {
		switch (modalSection) {
			case 'addCategory':
				return <CreateSection proyID={1} />;
			case 'editPercent':
				return <EditGlobalPercent data={1} />;
			default:
				return null;
		}
	};

	return (
		<Box m="20px">
			<Header title={data.title} subtitle={data.description} />
			<Box>
				<h6>Todos los Precios de Venta se aplican con el Porcentaje Global del: {porcentajeGlobal}%</h6>
			</Box>
			<ExpandableDataTable data={data} />
			<Modal
				open={modalOpen}
				onClose={handleModalClose}
				aria-labelledby="modal-modal-title"
				aria-describedby="modal-modal-description"
				sx={{
					display: 'flex',
					alignItems: 'center',
					justifyContent: 'center',
				}}
			>
				<Paper
					elevation={3}
					sx={{
						width: modalSection === 'editPercent' ? '400px' : '50%',
						padding: 3,
						backgroundColor: '#f5f5f5',
						borderRadius: 2
					}}
				>
					{renderModalContent()}
				</Paper>
			</Modal>
		</Box>
	)
};

export const CompoundDetails = ({ data, tipo, initValues }) => {
	const formatCurrency = (value) => {
		return new Intl.NumberFormat('es-MX', { style: 'currency', currency: 'MXN' }).format(value);
	};

	const navigate = useNavigate();
	const [rows, setRows] = useState([]);
	const { enqueueSnackbar } = useSnackbar();
	const [loading, setLoading] = useState(true);
	const [currentCantidad, setCurrentCantidad] = useState(initValues.precioCompra);
	const [selectedOption, setSelectedOption] = useState("automatico");

	const theme = useTheme();
	const colors = tokens(theme.palette.mode);

	const { config, configData } = UseAppConfig();

	const porcentajeGlobal = configData ? configData.porcentajeGlobal : 0;
	const [percentage, setPercentage] = useState(porcentajeGlobal);

	const [OpenConfirm, setConfirmOpen] = useState(false);
	const [ConfirmValue, setConfirmValue] = useState('');

	const [RcdID, setRcdID] = useState(0);
	const [RcdName, setRcdName] = useState('');

	const [artID, setArtID] = useState(false);
	const [modalOpen, setModalOpen] = useState(false);
	const [modalSection, setModalSection] = useState(false);

	const [activeTab, setActiveTab] = useState(0);

	const handleTabChange = (event, newValue) => {
		setActiveTab(newValue);
	};

	const handleChange = (id, totalGeneral, newValue) => {
		setRows((prevRows) =>
			prevRows.map((row) =>
				row.id === id
					? { ...row, cantidad: newValue, isChanged: true, total: row.precio * newValue, percentage: totalGeneral > 0 ? (row.precio * newValue / totalGeneral) * 100 : 0 }
					: row
			)
		);
	};

	const handleDisabledr = (id, newValue) => {
		setRows((prevRows) =>
			prevRows.map((row) =>
				row.id === id
					? { ...row, isConfirmed: true, isChanged: false }
					: row
			)
		);
	};

	const handleClick = async (id, newValue) => {
		if (newValue && newValue > 0) {
			try {
				const result = await sendPostData(`${config.API_BASE_URL}/edita/compuesto`, { id, cantidad: newValue });
				console.log('Formulario actualizado exitosamente:', result);
				handleDisabledr(id, newValue);
				enqueueSnackbar('Cantidad actualizada exitosamente', { variant: 'success' });
				try {
					const precioVentaResponse = await sendPostData(`${config.API_BASE_URL}/consulta/precioVenta`, { id: initValues.id });
					console.log('El nuevo Precio de Venta es:', JSON.stringify(precioVentaResponse));
					if (Array.isArray(precioVentaResponse) && precioVentaResponse.length > 0) {
						const precioVenta = precioVentaResponse[0];
						console.log('Precio de Compra:', precioVenta.precioCompra);
						setCurrentCantidad(precioVenta.precioCompra);
					}
				} catch (error) {
					console.error('Error al consultar el nuevo Precio de Venta:', error);
				}
			} catch (error) {
				console.error('Error al actualizar la cantidad de este artículo:', error);
				enqueueSnackbar('Error al Actualizar la Cantidad de este Artículo', { variant: 'error' });
			}
		}
	};

	const fetchDataTable = async () => {
		try {
			const jsonData = await getDataArray(`${config.API_BASE_URL}/${data.sendData}`, { id: initValues.id });
			const totalGeneral = jsonData.reduce((sum, row) => sum + (row.precio * row.cantidad), 0);

			const formatData = jsonData.map(row => {
				const total = row.precio * row.cantidad;
				const percentage = totalGeneral > 0 ? (total / totalGeneral) * 100 : 0;
				return {
					...row,
					status: row.statusElemento == 1 ? 'Activado' : 'Desactivado',
					cantidadAnterior: row.cantidad,
					total: total,
					totalGeneral: totalGeneral,
					percentage: percentage
				};
			});

			setRows(formatData);
			console.log('Detalles del Registro: ' + JSON.stringify(jsonData));
		} catch (error) {
			console.error('Se ha generado un error a la hora de intentar obtener los datos:', error);
			setRows([]);
		} finally {
			setLoading(false);
		}
	};

	useEffect(() => {
		fetchDataTable();
		if (initValues.precioVenta === "") {
			setSelectedOption("automatico");
		} else if (initValues.precioVenta < 0) {
			setSelectedOption("porcentaje");
		} else if (initValues.precioVenta > 0) {
			setSelectedOption("personalizado");
		}
	}, [initValues.id, data.sendData]);

	if (loading) {
		return <div>Cargando...</div>;
	}

	const reloadData = async () => {
		try {
			fetchDataTable();
		} catch (error) {
			enqueueSnackbar('Error al recargar los datos', { variant: 'error' });
		}
	};

	const handleConfirm = async () => {
		try {
			const delUsrID = await getPostData(`${config.API_BASE_URL}/retira/articuloCompuesto`, { id: RcdID });
			enqueueSnackbar('El Artículo ha sido Retirado de este Compuesto exitosamente', { variant: 'error' });
			reloadData();
			handleConfirmClose();
		} catch (error) {
			enqueueSnackbar('Error al intentar Retirar el Artículo de este Compuesto', { variant: 'error' });
			console.error('Se ha generado un error a la hora de intentar obtener los datos:', error);
		}
		console.log('Confirmado');
	};

	const articlesData = {
		padreID: initValues.id
	};

	const handleFormDelete = async (params) => {
		setRcdID(params.id);
		setRcdName(params.RcdName);
		setConfirmValue(params.ConfirmValue);
		setConfirmOpen(true);
	};

	const handleConfirmClose = () => {
		setConfirmOpen(false);
	};

	const handleRcdEdit = async (thisType, thisID, thisName) => {
		switch (thisType) {
			case 'viewArt':
				setArtID(thisID);
				setModalSection('viewArticle');
				setModalOpen(true);
				break;
		}
	};

	const renderModalContent = () => {
		switch (modalSection) {
			case 'viewArticle':
				return (
					<ViewArticle id={artID} precioVenta={''} />
				);
		}
	};

	const handleModalClose = () => {
		setModalOpen(false);
	};

	let modalWidth;
	let modalHeight;
	switch (modalSection) {
		case 'viewArticle':
			modalWidth = '800px';
			modalHeight = '500px';
			break;
		default:
			modalWidth = '700px';
			modalHeight = '400px';
	}

    const ColorsDefault = ({ id, nombre, tipo }) => {
        const styleConfig = {
            'Compuesto': { color: 'green', cursor: 'normal' },
            'Simple': { color: 'blue', cursor: 'normal' },
        };

        const defaultStyle = { color: 'red', fontSize: '14px', fontWeight: 'normal' };
        const appliedStyle = styleConfig[tipo] || defaultStyle;

        return (
            <span style={appliedStyle}>
                {nombre}
            </span>
        );
    };

	return (
		<Grid spacing={3}>
			{(() => {
				const dataConfig = {
					dbID: "id",
					title: "Editar los datos de " + initValues.descCorta,
					key: editArticle,
					section: 'articles',
					module: 'articles',
					description: "Ingrese los datos necesarios para editar los datos de este artículo",
					titleBtn: "Actualizar Datos",
					colorBtn: "secondary",
					msgSuccess: "Articulo editado exitosamente",
					msgError: "Error al editar el Articulo",
					getData: "detalle/articulo",
					sendData: "edita/articulo"
				}

				const validateSchemas = yup.object().shape({
					clave: yup.string().required('Requerido'),
					famID: yup.number().required('Requerido'),
					cuentaID: yup.number().required('Requerido'),
					unidadID: yup.number().required('Requerido'),
					descCorta: yup.string().required('Requerido'),
					descLarga: yup.string().required('Requerido'),
					status: yup.number().required('Requerido')
				});

				return (
					<>
						<Box>
							{/* Pestañas */}
							<Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
								{tipo === 'Simple' ? (
									<>
									</>
								) : (
									<>
										<Tabs
											value={activeTab}
											onChange={handleTabChange}
											aria-label="basic tabs example"
											sx={{
												'& .MuiTab-root': {
													backgroundColor: 'darkblue',
													color: 'white',
													fontWeight: 'bold',
													borderRadius: '5px',
													margin: '0 5px',
													minHeight: '48px',
												},
												'& .Mui-selected': {
													backgroundColor: 'cornflowerblue',
													color: 'white',
													fontWeight: 'bold',
													borderRadius: '5px',
													margin: '0 5px',
													minHeight: '48px',
												},
												'& .MuiTabs-indicator': {
													backgroundColor: 'transparent',
												},
											}}
										>
											<Tab label="Listado de Artículos" />
											<Tab label="Editar Compuesto" />
										</Tabs>
									</>
								)}
							</Box>

							{/* Contenido de las Pestañas */}
							{tipo === 'Simple' ? (
								<>
									{activeTab === 0 && (
										<Box sx={{ padding: 2 }}>
											<GeneraFormularioSimple
												data={dataConfig}
												initValues={initValues}
												validateSchemas={validateSchemas}
											/>
										</Box>
									)}
								</>
							) : (
								<>
									{activeTab === 0 && (
										<Box sx={{ padding: 2 }}>
											<AddArticles data={articlesData} initValues={initValues} />
											<Paper className="panel" style={{ marginTop: '40px' }}>
												<Box className="invoice-items">
													<Box sx={{ textAlign: 'left' }}>
														<h3>Este Compuesto está conformado por los siguientes Artículos:</h3>
														Tenga en cuenta que al modificar las cantidades tendrá que guardar cada registro de manera independiente
													</Box>
													<br /><br />
													<TableContainer>
														<Table>
															<TableHead>
																<TableRow style={{ backgroundColor: colors.grey[900] }}>
																	<TableCell className="per5 text-center" style={{ width: '120px' }}><b>Clave</b></TableCell>
																	<TableCell className="per5 text-center"><b>Tipo</b></TableCell>
																	<TableCell className="per25 text-center"><b>Precio</b></TableCell>
																	<TableCell className="per25 text-center"><b>Descripción</b></TableCell>
																	<TableCell className="per25 text-center"><b>Familia</b></TableCell>
																	<TableCell className="per5 text-center"><b>Cantidad</b></TableCell>
																	<TableCell className="per5 text-center"><b>Total</b></TableCell>
																	<TableCell className="per25 text-center"><b>Porcentaje</b></TableCell>
																	<TableCell className="per25 text-center"><b>Estado</b></TableCell>
																	<TableCell className="per5 text-center"><b>Acciones</b></TableCell>
																</TableRow>
															</TableHead>
															<TableBody>
																{rows.map((row) => (
																	<TableRow key={row.id}>
																		<TableCell
																			sx={{
																				cursor: 'pointer',
																				color: 'blue'
																			}}
																			className="text-center"
																			onClick={() => handleRcdEdit('viewArt', row.rcdID, row.hijo)}
																		>
																			<IconsDefault tipo={row.tipo} icon='' />
																			<span style={{ marginLeft: '5px', fontSize: '20px' }}></span>
																			<ColorsDefault
																				id={row.id}
																				nombre={row.hijo}
																				tipo={row.nombreTipo}
																			/>
																		</TableCell>
																		<TableCell className="text-center">{row.nombreTipo}</TableCell>
																		<TableCell className="text-center">{formatCurrency(row.precio)}</TableCell>
																		<TableCell className="text-center">{row.descripcion}</TableCell>
																		<TableCell className="text-center">{row.nombreFamilia}</TableCell>
																		<TableCell className="text-center">
																			<input
																				type="number"
																				value={row.cantidad}
																				style={{ width: '60px' }}
																				className="input-number"
																				onChange={(e) => handleChange(row.id, row.totalGeneral, e.target.value)}
																				disabled={!row.statusElemento || row.statusElemento === 'valor_especifico'}
																			/>
																		</TableCell>
																		<TableCell className={`text-center ${row.isConfirmed ? 'green-text' : row.isChanged ? 'yellow-text' : ''}`}>
																			{formatCurrency(row.total)}
																		</TableCell>
																		<TableCell className={`text-center ${row.isConfirmed ? 'green-text' : row.isChanged ? 'yellow-text' : ''}`}>
																			{row.percentage.toFixed(2)}%
																		</TableCell>
																		<TableCell className="text-center">{row.status}</TableCell>
																		<TableCell className="text-center">
																			<Box sx={{ display: 'flex', gap: '3px' }}>
																				<Button
																					type="submit"
																					color="inherit"
																					variant="contained"
																					sx={{
																						minWidth: 'auto',
																						padding: '5px 10px',
																						backgroundColor: row.isChanged ? 'green' : undefined,
																						'&:disabled': {
																							backgroundColor: 'grey',
																						},
																					}}
																					disabled={!row.isChanged}
																					onClick={() => handleClick(row.id, row.cantidad)}
																				>
																					<FontAwesomeIcon icon={faEdit} />
																				</Button>
																				<Button
																					type="submit"
																					color="error"
																					variant="contained"
																					sx={{ minWidth: 'auto', padding: '5px 10px' }}
																					onClick={() => {
																						handleFormDelete({
																							id: row.id,
																							ConfirmValue: 7,
																							RcdName: row.hijo
																						});
																					}}
																				>
																					<FontAwesomeIcon icon={faTrash} />
																				</Button>
																			</Box>
																		</TableCell>
																	</TableRow>
																))}
															</TableBody>
														</Table>
													</TableContainer>
												</Box>
												<Modal
													open={modalOpen}
													onClose={handleModalClose}
													aria-labelledby="modal-modal-title"
													aria-describedby="modal-modal-description"
													sx={{
														display: 'flex',
														alignItems: 'center',
														justifyContent: 'center',
													}}
												>
													<Paper
														elevation={3}
														sx={{
															width: modalWidth,
															maxHeight: modalHeight,
															overflowY: 'auto',
															padding: 3,
															backgroundColor: '#f5f5f5',
															borderRadius: 2
														}}
													>
														{renderModalContent()}
													</Paper>
												</Modal>
												<ConfirmationDialog
													open={OpenConfirm}
													onClose={handleConfirmClose}
													onConfirm={handleConfirm}
													statusValue={ConfirmValue}
													keyValue={RcdName}
												/>
											</Paper>
										</Box>
									)}

									{activeTab === 1 && (
										<Box sx={{ padding: 2, paddingTop: 10 }}>
											<GeneraFormularioSimple
												data={dataConfig}
												initValues={initValues}
												validateSchemas={validateSchemas}
											/>
										</Box>
									)}
								</>
							)}
						</Box>
					</>
				);

			})()}
		</Grid>
	);
};

export const GetCompoundAmounts = ({ data, tipo, initValues }) => {
	const formatCurrency = (value) => {
		return new Intl.NumberFormat('es-MX', { style: 'currency', currency: 'MXN' }).format(value);
	};

	const navigate = useNavigate();
	const [rows, setRows] = useState([]);
	const { enqueueSnackbar } = useSnackbar();
	const [loading, setLoading] = useState(true);
	const [currentCantidad, setCurrentCantidad] = useState(initValues.precioCompra);
	const [selectedOption, setSelectedOption] = useState("automatico");

	const theme = useTheme();
	const colors = tokens(theme.palette.mode);

	const { config, configData } = UseAppConfig();

	const porcentajeGlobal = configData ? configData.porcentajeGlobal : 0;
	const [percentage, setPercentage] = useState(porcentajeGlobal);

	const [OpenConfirm, setConfirmOpen] = useState(false);
	const [ConfirmValue, setConfirmValue] = useState('');

	const [RcdID, setRcdID] = useState(0);
	const [RcdName, setRcdName] = useState('');

	const [activeTab, setActiveTab] = useState(0);

	const fetchDataTable = async () => {
		try {
			const jsonData = await getDataArray(`${config.API_BASE_URL}/${data.sendData}`, { id: initValues.id });
			const totalGeneral = jsonData.reduce((sum, row) => sum + (row.precio * row.cantidad), 0);

			const formatData = jsonData.map(row => {
				const total = row.precio * row.cantidad;
				const percentage = totalGeneral > 0 ? (total / totalGeneral) * 100 : 0;
				return {
					...row,
					status: row.statusElemento == 1 ? 'Activado' : 'Desactivado',
					cantidadAnterior: row.cantidad,
					total: total,
					totalGeneral: totalGeneral,
					percentage: percentage
				};
			});

			setRows(formatData);
			console.log('Detalles del Registro: ' + JSON.stringify(jsonData));
		} catch (error) {
			console.error('Se ha generado un error a la hora de intentar obtener los datos:', error);
			setRows([]);
		} finally {
			setLoading(false);
		}
	};

	useEffect(() => {
		fetchDataTable();
		if (initValues.precioVenta === "") {
			setSelectedOption("automatico");
		} else if (initValues.precioVenta < 0) {
			setSelectedOption("porcentaje");
		} else if (initValues.precioVenta > 0) {
			setSelectedOption("personalizado");
		}
	}, [initValues.id, data.sendData]);

	if (loading) {
		return <div>Cargando...</div>;
	}

	const articlesData = {
		padreID: initValues.id
	};

	const handleSaleSubmit = async (values) => {
		switch (tipo) {
			case 'Simple':
				actualizaPrecioCompra(values);
				break;
			case 'Compuesto':
				actualizaPrecioVenta(values);
				break;
		}
	};

	const actualizaPrecioCompra = async (values) => {
		if (selectedOption === "automatico") {
			values.precioVenta = 0;
		}
		try {
			const result = await sendPostData(`${config.API_BASE_URL}/edita/precioCompra`, values);
			enqueueSnackbar(data.msgSuccess, { variant: 'success' });
			navigate(`/${data.module}`);
		} catch (error) {
			enqueueSnackbar(data.msgError, { variant: 'error' });
		}
	};

	const actualizaPrecioVenta = async (values) => {
		if (selectedOption === "automatico") {
			values.precioVenta = 0;
		}
		try {
			const result = await sendPostData(`${config.API_BASE_URL}/edita/precioVenta`, values);
			enqueueSnackbar('Precio de Venta actualizado exitosamente', { variant: 'success' });
			navigate(`/${data.module}`);
		} catch (error) {
			enqueueSnackbar(data.msgError, { variant: 'error' });
		}
	};

	const calculateAutoSalePrice = (basePrice) => {
		const result = basePrice / (1 - (percentage / 100));
		return formatCurrency(result);
	};

	return (
		<Grid item xs={12} sm={12}>
			<Box
				sx={{
					backgroundColor: "#f1f2f3",
					padding: 3,
					borderRadius: 2,
					boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
				}}
			>
				<Formik
					initialValues={initValues}
					onSubmit={handleSaleSubmit}
				>
					{({
						values,
						handleChange,
						setFieldValue,
						handleSubmit,
					}) => (
						<form onSubmit={handleSubmit}>
							{/* Precio de Compra */}
							<div>
								{tipo === 'Simple' && (
									<>
										<Box sx={{ textAlign: "left" }}>
											<b>Precio de Compra</b>
										</Box>
										<div style={{ display: "flex", marginTop: 10, marginBottom: 20, alignItems: "center", gap: "4px" }}>
											<h5>$</h5>
											<TextField
												name="precioCompra"
												label="Precio de Compra"
												type="number"
												value={values.precioCompra}
												onChange={handleChange}
												size="small"
												sx={{ width: "20%", marginBottom: 1 }}
											/>
										</div>
									</>
								)}
								{tipo === 'Compuesto' && (
									<>
										<b>Precio de compra del Compuesto</b>
										<p style={{ fontSize: "15px", fontWeight: "bold" }}>
											{formatCurrency(currentCantidad)}
										</p>
									</>
								)}
							</div>

							{/* Precio de Venta */}
							<Box>
								<Box sx={{ textAlign: "left", marginBottom: 2 }}>
									<b>Precio de Venta</b>
									<div style={{ paddingTop: '10px' }}>
										{selectedOption === "automatico" && (
											<>
												<span
													style={{
														fontSize: "15px",
														height: "45px",
														display: "block",
														paddingTop: "10px",
													}}
												>
													Cálculo Automático: {calculateAutoSalePrice(currentCantidad)}
												</span>
												<TextField
													type="hidden"
													value={0}
													name="precioVenta"
													onChange={(e) => {
														const inputValue = e.target.value;
														setFieldValue("precioVenta", inputValue === "" ? 0 : 0);
													}}
													sx={{ display: "none" }}
												/>
											</>
										)}

										{selectedOption === "porcentaje" && (
											<div style={{ display: "flex", alignItems: "center", gap: "8px" }}>
												<span style={{ fontSize: "15px" }}>Porcentaje Personalizado:</span>
												<TextField
													type="number"
													label="Porcentaje"
													value={
														values.precioVenta < 0 ? Math.abs(values.precioVenta) : "0"
													}
													name="precioVenta"
													size="small"
													onChange={(e) => {
														const inputValue = e.target.value;
														setFieldValue("precioVenta", inputValue === "" ? "" : -Math.abs(Number(inputValue)));
													}}
													sx={{
														width: "15%",
														marginBottom: 1,
													}}
												/>
												<h5>%</h5>
											</div>
										)}

										{selectedOption === "personalizado" && (
											<div style={{ display: "flex", alignItems: "center", gap: "8px" }}>
												<span style={{ fontSize: "15px" }}>Precio de Venta Personalizado:</span>
												<h5>$</h5>
												<TextField
													type="number"
													label="Precio de Venta"
													value={values.precioVenta < 0 ? "" : values.precioVenta}
													name="precioVenta"
													size="small"
													onChange={(e) => {
														const inputValue = e.target.value;
														setFieldValue("precioVenta", inputValue === "" ? "" : Number(inputValue));
													}}
													sx={{
														width: "20%",
														marginBottom: 1,
													}}
												/>
											</div>
										)}
									</div>
								</Box>

								{/* Opciones de Selección */}
								<RadioGroup
									value={selectedOption}
									onChange={(e) => {
										const value = e.target.value;
										setSelectedOption(value);
									}}
									sx={{
										textAlign: "center",
										display: "flex",
										flexDirection: "row",
										justifyContent: "center",
										gap: 2,
									}}
								>
									<FormControlLabel
										value="automatico"
										control={<Radio />}
										label="Cálculo Automático"
									/>
									<FormControlLabel
										value="porcentaje"
										control={<Radio />}
										label="Porcentaje"
									/>
									<FormControlLabel
										value="personalizado"
										control={<Radio />}
										label="Establecer Precio"
									/>
								</RadioGroup>

								<Box
									sx={{
										display: "flex",
										justifyContent: "center",
										alignItems: "center",
										marginTop: 2, // Espaciado superior opcional
									}}
								>
									<Button
										type="submit"
										variant="contained"
										color="info"
										size="small"
										sx={{ padding: 2 }}
									>
										{data.titleBtn2}
									</Button>
								</Box>
							</Box>
						</form>
					)}
				</Formik>
			</Box>
		</Grid>
	);
};