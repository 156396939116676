import React from 'react';
import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Button } from '@mui/material';

const ConfirmationDialog = ({ open, onClose, onConfirm, statusValue, keyValue }) => {
    let dialogTitle, dialogContent, btnColor, confirmButtonText, btnCancelColor, cancelBtnText;
    switch ( statusValue ) {
        case 0:
            dialogTitle = "Activar Artículo";
            dialogContent = "Está seguro de querer activar este artículo?";
            btnCancelColor = "error";
            cancelBtnText = "Cancelar";
            btnColor = "success";
            confirmButtonText = "Activar";
            break;
        case 1:
            dialogTitle = "Desactivar Artículo";
            dialogContent = "Está seguro de querer desactivar este artículo?";
            btnCancelColor = "warning";
            cancelBtnText = "Cancelar";
            btnColor = "warning";
            confirmButtonText = "Desactivar";
            break;
        case 2:
            dialogTitle = `Retirar Categoría ${keyValue}?`;
            dialogContent = (
                <>
                    Está seguro de querer retirar la categoría <strong>{keyValue}</strong> y todo su contenido?
                </>
            );
            btnCancelColor = "warning";
            cancelBtnText = "Cancelar";
            btnColor = "error";
            confirmButtonText = "Retirar";
            break;
        case 3:
            dialogTitle = `Retirar Artículo ${keyValue}?`;
            dialogContent = (
                <>
                    Está seguro de querer retirar el artículo <strong>{keyValue}</strong> de esta categoría?
                </>
            );
            btnCancelColor = "warning";
            cancelBtnText = "Cancelar";
            btnColor = "error";
            confirmButtonText = "Retirar";
            break;
        case 4:
            dialogTitle = `Eliminar Artículo Simple ${keyValue}?`;
            dialogContent = (
                <>
                    Está seguro de querer eliminar completamente el artículo <strong>{keyValue}</strong>?
                </>
            );
            btnCancelColor = "warning";
            cancelBtnText = "Cancelar";
            btnColor = "error";
            confirmButtonText = "Retirar";
            break;
        case 5:
            dialogTitle = `Eliminar Artículo Compuesto ${keyValue}?`;
            dialogContent = (
                <>
                    Está seguro de querer eliminar completamente este artículo compuesto y todo su contenido?
                </>
            );
            btnCancelColor = "warning";
            cancelBtnText = "Cancelar";
            btnColor = "error";
            confirmButtonText = "Retirar";
            break;
        case 6:
            dialogTitle = `Eliminar Usuario ${keyValue}?`;
            dialogContent = (
                <>
                    Está seguro de querer eliminar este Usuario?
                </>
            );
            btnCancelColor = "warning";
            cancelBtnText = "Cancelar";
            btnColor = "error";
            confirmButtonText = "Retirar";
            break;
        case 7:
            dialogTitle = `Retirar el artículo ${keyValue} de este Compuesto?`;
            dialogContent = (
                <>
                    Está seguro de querer retirar este artículo del Compuesto?
                </>
            );
            btnCancelColor = "warning";
            cancelBtnText = "Cancelar";
            btnColor = "error";
            confirmButtonText = "Retirar";
            break;
    }

    return (
        <Dialog
            open={open}
            onClose={onClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle id="alert-dialog-title">{dialogTitle}</DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-description">
                    {dialogContent}
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button variant="contained" onClick={onClose} color={btnCancelColor} autoFocus>
                    {cancelBtnText}
                </Button>
                <Button variant="contained" onClick={onConfirm} color={btnColor} autoFocus>
                    {confirmButtonText}
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default ConfirmationDialog;
